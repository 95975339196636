import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
import { MenuItem } from '@material-ui/core';
import './userSystemSelectForm.css'
import { useTheme } from '@material-ui/styles';
import UserSystemSelectFormItem from '../UserSystemSelectFormItem/UserSystemSelectFormItem';
import { getFromSafeObject, isNotEmptyObject } from '../../aux/aux';

const UserSystemSelectForm = ({ userSystems, availableSystems=null, currentUserSystemId, isDisabled=false, setFormValues=()=>{} }) => {
    const { t } = useTranslation('common', { keyPrefix: 'userSystemSelectForm'})
    const [contentSystemEditForm, setContentSystemEditForm] = useState({
        selectedSystemIndex: '',
    });

    const onChange = (e) => {
        e.preventDefault();
        setContentSystemEditForm((contentSystemEditForm) => ({...contentSystemEditForm, [e.target.name]: e.target.value}))
    }

    useEffect(() => {
        if(contentSystemEditForm.selectedSystemIndex !== ''){
            const system = userSystems[contentSystemEditForm.selectedSystemIndex]
            const updatedFormValues = {
                selectedDeviceName : system.deviceName,
                selectedSoftwareName : system.softwareName,
                selectedUserSystemId : system.id,
            };
            setFormValues(updatedFormValues)
        }
    },[contentSystemEditForm])

    return (
        <>
            <Grid item className='class-content-system-select-form-item'>
                <TextField 
                label={t("selectYourSystem")}
                name='selectedSystemIndex'
                value={contentSystemEditForm.selectedSystemIndex}
                onChange={onChange} 
                inputProps={{style:{textOverflow:'ellipsis'}}}
                select 
                fullWidth
                variant='standard'
                disabled= {isDisabled}
                required
                >
                    {userSystems.map( (system, index) => {
                        let Component
                        const isSelected = currentUserSystemId && system.id === currentUserSystemId ;
                        if(isNotEmptyObject(availableSystems)){
                            const softwareArray = getFromSafeObject(availableSystems, 'softwareArray', []);
                            const deviceArray = getFromSafeObject(availableSystems, 'deviceArray', []);
                            const versionedSystemArray = getFromSafeObject(availableSystems, 'systemLessonVersionArray', []);
                            let softwareAvail = softwareArray.find(software => software.softwareName.toLowerCase() === system.softwareName.toLowerCase()) != null;
                            let deviceAvail = deviceArray.find(device => device.deviceName.toLowerCase() === system.deviceName.toLowerCase()) != null;
                            const isSystemAvail = softwareAvail && deviceAvail;
                            const isVersioned = versionedSystemArray.find(versionedSystem => versionedSystem.deviceName.toLowerCase() === system.deviceName.toLowerCase() && versionedSystem.softwareName.toLowerCase() === system.softwareName.toLowerCase())
                            Component = 
                                <MenuItem key={index} value={index} disabled={!isSystemAvail}>
                                    <UserSystemSelectFormItem isCompatible={isSystemAvail} isVersioned={isVersioned} isSelected={isSelected} system={system} />
                                </MenuItem>
                        }else{
                            Component = 
                                <MenuItem key={index} value={index}>
                                    <UserSystemSelectFormItem system={system} isSelected={isSelected} showCompatibilityLabel={false} />
                                </MenuItem>
                        }
                        return(Component)
                    })}
                </TextField>
            </Grid>
        </>
    )
}

export default UserSystemSelectForm