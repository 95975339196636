import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Divider } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkUnwrapImages from 'remark-unwrap-images'
import remarkGfm from 'remark-gfm';
import { defaultSchema } from 'rehype-sanitize';
import { getReadableFile } from '../../aux/staticContentQueries';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import noConnection from '../../lotties/noConnection_200x200.json';
import { textTransform } from '../../aux/aux';
import './markdownDisplay.css'
import { Link } from 'react-router-dom';

const MarkdownDisplay = ({ path, onError=null, onReady=()=>{}, wrapperClassName='', className='', customSanitizeSchema=null, customComponentMapping=null }) => {
    const { t } = useTranslation('common', { keyPrefix: 'markdownDisplay' });
    const [readable, setReadable] = useState({md:""});
    const [isError, setIsError] = useState(null);
    const [animationContainer ,setAnimationContainer] = useState(null);
    const animationRef = useRef(null);
    const styles = {
        title:{
            // color: theme.palette.primary.darker,
            fontWeight: 'bold'
        },
        errorTitle:{
            // color: theme.palette.primary.darker,
            fontWeight: 'bold',
            textAlign: 'center'
        },
        errorMsg:{
            whiteSpace:'pre-line',
            textAlign: 'center'
        },
        divider : {
            backgroundColor: '#f3f3f3'
        }
    }
    
    const sanitizeSchema = customSanitizeSchema || {
        ...defaultSchema,
        attributes: {
            ...defaultSchema.attributes,
            p: [
                ...(defaultSchema.attributes.p || []),
                'className'
            ],
            span: [
                ...(defaultSchema.attributes.span || []),
                'className'
            ]
        }
    }

    const markdownComponentsMapping = customComponentMapping || {
        h1: (node, ...props) => <><Typography variant="h4" {...node.node.properties} className={node.className || ''} children={node.children} /> <Divider style={ styles.divider }/> </>,
        h2: (node, ...props) => <Typography variant="h5" {...node.node.properties} className={node.className || ''} children={node.children} />,
        h3: (node, ...props) => <Typography variant="h6" {...node.node.properties} className={node.className || ''} children={node.children} />,
        p:  (node, ...props) => <Typography variant="body1" {...node.node.properties} className={node.className || ''} children={node.children}/>,
        // li: (node, ...props) => <li><Typography variant="body1" {...node.node.properties} children={node.children}/></li>,
        a: (node, ...props) => {
            if(node.type === 'local'){
                return <Link to={node.href} className={node.className || ''} children={node.children}/> 
            }else{
                return <a {...node.node.properties} className={node.className || ''} children={node.children} />
            }
        }
    };
    
    const getReadable = async (path, signal) => {
        try{
            if(typeof onError !== 'function') setIsError(false);
            const res = await getReadableFile(path, signal);
            const md = res.data;
            if(!signal.aborted){
                setReadable({ md });
            }
        }catch(error){
            if(!signal.aborted){
                if(typeof onError !== 'function'){
                    setIsError(true);
                }else{
                    onError();
                }
            }
        }finally{
            if(!signal.aborted){
                onReady();
            }
        }
    }
    
    useEffect(() => {
        const abortController = new AbortController();
        getReadable(path, abortController.signal);
        return(() => {
            abortController.abort();
        })
    }, [path]);

    useEffect(() => {
        if(animationContainer){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                autoplay: true,
                animationData: noConnection,
                loop: false,
                name: 'animation'
            })
            return(() => {
                if(animationRef.current != null){
                    animationRef.current.stop();
                    animationRef.current?.destroy();
                }
            })
        }
    },[animationContainer])

    return (
        <div className={wrapperClassName}>
            {isError ? 
                <div className='markdown-display-error-container'>
                    <div ref={setAnimationContainer} className='markdown-display-error-animation' />
                    <Typography variant='h5' style={styles.errorTitle}>
                        {textTransform('title', t('uups'))}
                    </Typography>
                    <Typography variant='body1' style={styles.errorMsg}>
                        {`${textTransform('title', t('thisDocIsLostInSpace'))}. ${textTransform('title', t('weCannotFindItRightNow'))}.\n${textTransform('title', t('tryItLater'))}`}
                    </Typography>
                </div>
                :
                <ReactMarkdown 
                    children={readable.md} 
                    remarkPlugins={[remarkGfm]} 
                    rehypePlugins={[rehypeRaw, [rehypeSanitize, sanitizeSchema]]} 
                    className={`markdown-display-main-container ${className}`} 
                    components={markdownComponentsMapping} 
                    includeElementIndex={true}
                />
            }
        </div>
    )
}

export default MarkdownDisplay