import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { textTransform } from '../../aux/aux';
import CustomPopover from '../CustomPopover/CustomPopover';
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
// TODO: implement default img
// import defaultPanelistImg from '../../img/default/example.jpg'
import SocialProfileBadge  from '../SocialProfileBadge/SocialProfileBadge';
import PreventUnderZLayerInteraction from '../PreventUnderZLayerInteraction/PreventUnderZLayerInteraction';
import { useUpdateScrollableDocumentCtx } from '../../customHooks/useUpdateScrollableDocumentCtx';
import './socialProfileBadgeWithPopover.css';

const SocialProfileBadgeWithPopover = ({ imgId, imgUrl, label, popOverDiameterPx=60, diameterPx=30, style, className, palette }) => {
  const IMG_RESOLUTION_SCALE_FACTOR = 3;
  const badgeRef = useRef();
  const setIsScrollableDocument = useUpdateScrollableDocumentCtx();
  const [isClick, setIsClick] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [popOver, setPopOver] = useState(null);
  const [imgPanelistUrl, setImgPanelistUrl] = useState(null);

  useEffect(() => {
    if(imgId || imgUrl){
      let imgPanelistUrl;
      let auxImgUrl;
      if(imgUrl){
        imgPanelistUrl = imgUrl
      }else if(imgId){
        const assetProvider = new ImgDN(cloudinary);
        auxImgUrl = assetProvider.getAssetUrl(imgId,{height: diameterPx * IMG_RESOLUTION_SCALE_FACTOR});
      }
      imgPanelistUrl = imgPanelistUrl || auxImgUrl;
      setImgPanelistUrl(imgPanelistUrl);
    }
  },[imgId, imgUrl])

  useEffect(() => {
    if(isClick){
      setIsScrollableDocument(false);
      const handleClickOutside = (e) => {
          if(popOver && !popOver.contains(e.target)){
              setIsClick(false);
          }
      }
      document.addEventListener('pointerdown', handleClickOutside);
      return(() => {
          document.removeEventListener('pointerdown', handleClickOutside);
          setIsScrollableDocument(true)
      })
  }
  },[isClick])

  return (
    <>
      {isClick &&
        <PreventUnderZLayerInteraction/>
      }
      <div 
        ref={badgeRef} 
        className='social-profile-badge-with-popover-main-wrapper' 
        onMouseEnter={popOver != null ? () => setIsHover(true) : null}
        onMouseLeave={popOver != null ? () => setIsHover(false) : null}
        onPointerDown={popOver != null ? (e) => {
            e.stopPropagation();
            setIsClick(true);
        } : null}
      >
        {label ? 
          <CustomPopover 
          open={isHover || isClick} 
          anchorEl={badgeRef} 
          anchorOrigin={{vertical:'bottom', horizontal:'center'}} 
          transformOrigin={{vertical:'top', horizontal:'center'}}
          >
            <div ref={setPopOver} className='social-profile-badge-with-popover-popover'>
              <SocialProfileBadge 
                className={className}
                diameterPx={popOverDiameterPx} 
                url={imgPanelistUrl} 
                charToDisplay={label ? label[0] : null}
                palette={palette}
                style={{...style, marginRight: '10px'}}
              />
              <Typography variant='body2'>
                {textTransform('title', label)}
              </Typography>
            </div>
          </CustomPopover>
          :
          null
        }
        <SocialProfileBadge className={className} diameterPx={diameterPx} url={imgPanelistUrl} charToDisplay={label ? label[0] : null} palette={palette} style={style}/>
      </div>
    </>
  ) 
}

export default SocialProfileBadgeWithPopover