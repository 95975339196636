import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// Img and Art
import CompletedLectureIcon from '../../img/checkCircleOutline.svg';
import ReadingLectureIcon from '../../img/reading-lecture-icon.svg';
import VideoLectureIcon from '../../img/video-lecture-icon.svg';
import DownloadLectureIcon from '../../img/download-lecture-icon.svg';
import LiveLectureIcon from '../../img/live-lecture-icon.svg';

// Style
import './classSideBarElement.css';
import { textTransform, textTruncate } from '../../aux/aux';
import CustomPopover from '../CustomPopover/CustomPopover';

const ClassSideBarElement = ({ lessonId, lesson, sectionId, isCompleted, setContentSelected, contentSelected}) => {
    const theme = useTheme();
    const { t } = useTranslation('common');
    const MAX_DISPLAYABLE_TITLE_CHAR_LENGTH = 120; 
    const selectedElementBgColor = '#f3f3f3';
    const completedElementColor = theme.palette.success.main;
    const selectedElementFontColor = theme.palette.primary.main;
    const wrapperRef = useRef(null);
    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const isSelected = () => {
        return contentSelected.currentLessonId === lessonId
    }
    const styles = {
        elementWrapper: {
            ...( isSelected() ?
                {
                    backgroundColor: selectedElementBgColor,
                    color: selectedElementFontColor,
                }
                :
                {}
            )
        },
        elementIcon: {
            ...( isSelected() ? { fill: selectedElementFontColor } : {} ),
            ...( isCompleted ? { fill: completedElementColor } : {} ),
            height: '1.2em',
            marginRight: '1em',
            display: 'block'
        }
    }

    const handleOnMouseEnter = () => {
        if(lesson.description.title.length > MAX_DISPLAYABLE_TITLE_CHAR_LENGTH){
            setIsOpenPopover(true);
        }
    }

    const handleOnMouseLeave = () => {
        setIsOpenPopover(false);
    }

    const getElementIcon = (type, isCompleted) => {
        if (isCompleted) {
            return <CompletedLectureIcon style={styles.elementIcon} />
        } else {
            switch(type) {
                case "video":
                    return <VideoLectureIcon style={styles.elementIcon} />
                case "reading":
                    return <ReadingLectureIcon style={styles.elementIcon} />
                case "download":
                    return <DownloadLectureIcon style={styles.elementIcon} />
                case "live":
                    return <LiveLectureIcon style={styles.elementIcon} />
                default:
                    return null
            }
        }
    }

    const updateContentSelected = (e) => {
        setContentSelected(sectionId, lessonId)
    }



    return (
        <div className='class-side-element-main' style={styles.elementWrapper} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
            <CustomPopover anchorEl={wrapperRef} open={isOpenPopover} 
            timeTransform={null}
            anchorOrigin={{vertical:'center', horizontal:'left'}} 
            transformOrigin={{vertical:'center', horizontal:'right'}}>
                <Typography variant='body2'>
                    {textTransform('title', lesson.description.title)}
                </Typography>
            </CustomPopover>
            <Grid ref={wrapperRef} className="class-side-element-wrapper" container onClick={updateContentSelected}>
                <Grid item className='class-side-element-icon-wrapper'>
                    {getElementIcon(lesson.lessonTypeName, isCompleted)}
                </Grid>
                <Grid item xs>
                    <Typography variant="subtitle2" color="inherit">
                        {textTruncate(textTransform('title', lesson.description.title), MAX_DISPLAYABLE_TITLE_CHAR_LENGTH)}
                    </Typography>
                    <Typography variant="subtitle2">
                        {`${lesson.lengthInMin} ${t('minuteAbbr')}`}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default ClassSideBarElement
