import { useTheme } from '@material-ui/styles'
import { Typography, hexToRgb, rgbToHex } from '@material-ui/core'
import { useEffect } from 'react'
import { useState } from 'react'
import ImgLazyLoad from '../ImgLazyLoad/ImgLazyLoad'
import ImgDN from '../../cdn/imgDN'
import cloudinary from '../../cdn/providers/cloudinary'
import './socialProfileBadge.css'
import { isNotEmptyObject, randomRgbColor, rgbLightnessModifyOrUndefined } from '../../aux/aux'
import { useImgURL } from '../../customHooks/useImgURL'

const getSocialProfilePalette = (theme) => {
    const rgb1 = hexToRgb(theme.palette.primary.lighter);
    const rgb2 = hexToRgb(theme.palette.secondary.lighter);
    let lighter = randomRgbColor(rgb1, rgb2);
    const light = rgbLightnessModifyOrUndefined(lighter, -0.1);
    const main = rgbLightnessModifyOrUndefined(lighter, -0.3);
    const dark = rgbLightnessModifyOrUndefined(lighter, -0.5);
    return {lighter, light, main, dark}
}

const SocialProfileBadge = ({ uri, ProfileIcon, charToDisplay, url=null, diameterPx=30, style={}, className='', palette=null, imgLazyLoadThreshold='600px'}) => {
    const theme = useTheme()
    const [Profile, setProfile] = useState(null);
    const [imgURL, setImgURL] = useImgURL(uri, url);
    const [profilePalette, setProfilePalette] = useState(null);
    const styles = {
        icon:{
            display: 'block',
            height: 'inherit',
            width: 'inherit',
            fill: profilePalette?.dark || theme.palette.text.primary,
        },
        img:{
            height: 'inherit',
            width: 'inherit',
            objectFit: 'cover'
        },
        char:{
            fontWeight: 'bold',
            textAlign: 'center',
        },
        mainContiner:{
            height: diameterPx,
            width: diameterPx,
            overflow: 'hidden',
            border: 'solid 1px',
            color: profilePalette?.main || theme.palette.text.primary,
            borderColor: profilePalette?.main || theme.palette.common.white,
            backgroundColor: profilePalette?.lighter || theme.palette.common.white,
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        loading:{
            height: diameterPx,
            width: diameterPx
        }
    }

    const isProperProfilePalette = (palette) => {
        return isNotEmptyObject(palette) && palette.hasOwnProperty('light') 
            && palette.hasOwnProperty('lighter') && palette.hasOwnProperty('main')
            && palette.hasOwnProperty('dark')
    }

    useEffect(() => {
        setImgURL(uri, url);
    },[uri, url])

    useEffect(() => {
        let profile;
        if(imgURL){ 
            profile = <ImgLazyLoad src={imgURL} alt='social-profile-img' style={styles.img} threshold={imgLazyLoadThreshold}/>
        }else if(ProfileIcon){
            profile = <ProfileIcon style={styles.icon} />
        }else if(charToDisplay){
            profile = <Typography variant='body2' style={styles.char}>{charToDisplay}</Typography>
        }else{
            profile = null
        }
        setProfilePalette(() => {
            const rgb1 = hexToRgb(theme.palette.primary.lighter);
            const rgb2 = hexToRgb(theme.palette.secondary.lighter);
            let lighter = randomRgbColor(rgb1, rgb2);
            const light = rgbLightnessModifyOrUndefined(lighter, -0.1)
            const main = rgbLightnessModifyOrUndefined(lighter, -0.3)
            const dark = rgbLightnessModifyOrUndefined(lighter, -0.5)
            setProfilePalette({lighter, light, main, dark})
        })
        setProfile(profile)
    },[imgURL, ProfileIcon, charToDisplay])

    useEffect(() => {
        let profilePalette;
        if(isProperProfilePalette(palette)){
            profilePalette = palette;
        }else{
            profilePalette = getSocialProfilePalette(theme);
        }
        setProfilePalette(profilePalette);
    },[palette])

    return (
        Profile && profilePalette ?
            <div className={className} style={{...styles.mainContiner, ...style}}>
                {Profile}
            </div>
            :
            null
    )
}

export { SocialProfileBadge as default, getSocialProfilePalette }