import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import PopUpCreateAccount from '../PopUpCreateAccount/PopUpCreateAccount';
import './landingCenteredCTA.css'
import StyledHTMLString from '../StyledHTMLString/StyledHTMLString';

const LandingCenteredCTA = ({ user, setUser}) => {
    const theme = useTheme();
    const { t } = useTranslation('landing', { keyPrefix: 'landingCenteredCTA' }); 
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
    const styles = {
        wrapper: {
            backgroundColor: theme.palette.background.dark,//theme.palette.surface.light[0],
            color: theme.palette.text.contrast
        },
        title: {
            fontWeight:'bold',
            color: theme.palette.text.contrast
        },
        button: {}
    }

    const handleOnClick = () => {
        setShowCreateAccountModal(true)
    }

    return (
        <div className='landing-centered-cta-wrapper' style={styles.wrapper}>
            {showCreateAccountModal &&
                <PopUpCreateAccount showModal={showCreateAccountModal} setShowModal={setShowCreateAccountModal} setUser={setUser} user={user} passiveVerification={process.env.REACT_APP_TARGET_ENV === 'staging'}/>
            }
            <div className='landing-centered-cta-main-container'>
                <StyledHTMLString typographyProps={{className:'landing-centered-cta-title', variant:matchMobile ? 'h6':'h5', style:styles.title}}>
                    {t('readyToTakeAdvantageToYourSystem')}
                </StyledHTMLString>
                <StyledHTMLString typographyProps={{className:'landing-centered-cta-subtitle', variant:matchMobile ? 'body1':'h6', style:styles.subtitle}}>
                    {t('readyToTakeAdvantageToYourSystemDescription')}
                </StyledHTMLString>
                <Button 
                    onClick={handleOnClick}
                    variant='contained' 
                    color='primary' 
                    style={styles.button}
                    size='large'
                >
                    {t('common:joinNow')}
                </Button>
            </div>
        </div>
    )
}

export default LandingCenteredCTA