/*
    This module contains a set of functions to handle the API queries related to the user
*/
import { arrayToQueryParam } from "./aux";
import { createAuthRequest, publicRequest } from "./requestMethods";

const getUserSubscriptionFn = async (params, signal=null) => {
    const {
        userId,
        localeId
    } = params
    const userRequest = createAuthRequest();
    const res = await userRequest.get(`/users/get-subscription/${userId}/${localeId}`, {signal});
    return res;
}

const addProductToUserSubscriptionFn = async (params, signal=null) => {
    const {
        userId,
        productDetails,
        quantity,
        effectiveAt
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/users/add-product-to-subscription/user/${userId}`, {productDetails, quantity, effectiveAt}, { signal })
}

const updateUserBillingAddressAndCurrencyFn = async (params) => {
    const {
        userId,
        billingAddressAndCurrency,
    }= params
    const userRequest = createAuthRequest();
    const res = await userRequest.post(`/users/update-billing-address-and-currency/${userId}`, { billingAddressAndCurrency })
    return res;
}

const getUserProfileFn = async (params, signal=null) => {
    const {
        userId,
        localeId
    } = params
    const userRequest = createAuthRequest();
    return userRequest.get(`/users/get-profile/${userId}`, { params:{ localeId }, signal})
}

const getUserEmailPreferencesFn = async (params, signal=null) => {
    const {
        userId,
    } = params
    const userRequest = createAuthRequest();
    return userRequest.get(`/users/email-preferences/${userId}`, {signal})
}

const updateUserBasicDataFn = async (params, signal=null) => {
    const {
        userId,
        updatedValues,
    }= params
    if(updatedValues.hasOwnProperty('professionName')){
        updatedValues.professionName = updatedValues.professionName || null;
    }
    const userRequest = createAuthRequest();
    const res = await userRequest.post(`/users/update-basic-data/${userId}`, { updatedValues }, { signal })
    return res;
}

const requestUpdateUserEmailFn = async (params, signal=null) => {
    const {
        userId,
        newEmail,
        password
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/users/request-update-email/${userId}`, { newEmail, password }, { signal });
}

const updateUserEmailByTokenFn = async (params, signal=null) => {
    const data = {};
    const config = {
        headers: {authorization : `Bearer ${params.updateEmailToken}`},
        signal,
    }
    return publicRequest.post(`/users/update-email-by-token`, data, config )
}

const getUserProductPurchasabilityDetailsCollectionFn = async (params, signal=null) => {
    const {
        userId,
        productDetailsArray,
        asMap
    } = params
    const userRequest = createAuthRequest();
    return userRequest.post(`/users/product-purchasability/collection/user/${userId}`, { productDetailsArray }, { params:{ asMap }, signal})
}

const getUserProductConsumptionOptionMapFn = async (params, signal=null) => {
    const {
        userId,
        productDetails,
        ...query
    } = params;
    const {
        productId,
        editionNum
    } = productDetails;
    const userRequest = createAuthRequest();
    return userRequest.get(`/users/consumption-option-map/user/${userId}/product/${productId}${editionNum ? `/edition/${editionNum}` : ''}`, { params:query, signal });
}

const getUserProductConsumptionOptionFn = async (params, signal=null) => {
    const {
        userId,
        productDetails,
        ...query
    } = params;
    const {
        productId,
        editionNum
    } = productDetails;
    const userRequest = createAuthRequest();
    return userRequest.get(`/users/consumption-option/user/${userId}/product/${productId}${editionNum ? `/edition/${editionNum}` : ''}`, { params:query, signal });
}

const getUserAccessToProductDetailsCollectionFn = async(params, signal=null) => {
    const {
        userId,
        productDetailsArray,
        asMap
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/users/access-to-product-details/collection/user/${userId}`, { productDetailsArray }, { params:{ asMap }, signal })
}

const getUserSystemsFn = async (params, signal=null) => {
    const {
        userId,
        expand
    } = params;
    const userRequest = createAuthRequest();
    const query = expand ? `?expand=${expand}` : '';
    return userRequest.get(`/users/get-systems/${userId}${query}`, {signal: signal})
}

const setDefaultUserSystemFn = async (params, signal=null) => {
    const {
        userId,
        userSystemId
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/users/set-default-system/user/${userId}/userSystemId/${userSystemId}`,{}, { signal })
}

const getUserDefaultSystemFn = async (params, signal=null) => {
    const {
        userId,
        expand
    } = params;
    const userRequest = createAuthRequest();
    const query = { expand }
    return userRequest.get(`/users/get-default-system/${userId}`,{ query, signal })
}

const getUserDefaultPaymentMethodFn = async (params, signal=null) => {
    const {
        userId,
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.get(`/users/default-payment-method/${userId}`,{ signal })
}

const getUserContactDataFn = async (params, signal=null) => {
    const {
        userId,
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.get(`/users/contact-data/${userId}`,{ signal })
}

const deleteUserPaymentMethodFn = async (params, signal=null) => {
    const {
        userId,
        paymentMethodId
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.delete(`/users/payment-method/${userId}/${paymentMethodId}`,{ signal })
}

const createNewUserSystemFn = async (params, signal=null) => {
    const {
        userId,
        ...body
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/users/create-new-system/${userId}`, body, { signal });
}

const getUserTrakingTokenFn = async(params, signal=null) => {
    const {
        userId,
        itemIdArray,
        expiresIn
    } = params;
    const userRequest = createAuthRequest();
    let query = itemIdArray instanceof Array && itemIdArray.length > 0 ? `?itemIdArray=${arrayToQueryParam(itemIdArray)}`: '';
    query = expiresIn ? query ? `${query}&expiresIn=${expiresIn}` : `?{expiresIn=${expiresIn}}` : query
    return userRequest.get(`/users/traking-token/user/${userId}${query}`, { signal });
}

const postUserProductFeedbackFn = async(params, signal=null) => {
    const {
        userId,
        productFeedbackMap,
        opId,
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/users/product-feedback/user/${userId}/opId/${opId}`, { productFeedbackMap }, { signal });
}

const postUserProductNotificationFn = async(params, signal=null) => {
    const {
        userId,
        productNotificationMap,
        opId,
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/users/product-notification/user/${userId}/opId/${opId}`, { productNotificationMap }, { signal });
}


export{
    getUserSubscriptionFn,
    addProductToUserSubscriptionFn,
    updateUserBillingAddressAndCurrencyFn,
    getUserProductPurchasabilityDetailsCollectionFn,
    getUserAccessToProductDetailsCollectionFn,
    getUserProductConsumptionOptionMapFn,
    getUserProductConsumptionOptionFn,
    getUserSystemsFn,
    getUserDefaultSystemFn,
    getUserDefaultPaymentMethodFn,
    getUserContactDataFn,
    deleteUserPaymentMethodFn,
    setDefaultUserSystemFn,
    createNewUserSystemFn,
    getUserProfileFn,
    getUserEmailPreferencesFn,
    updateUserBasicDataFn,
    requestUpdateUserEmailFn,
    updateUserEmailByTokenFn,
    getUserTrakingTokenFn,
    postUserProductFeedbackFn,
    postUserProductNotificationFn
}