import { hexToRgb } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import ContentDescription from '../ContentDescription/ContentDescription'
import ContentPreview from '../ContentPreview/ContentPreview'
import './contentPreviewDescriptionFeaturesAndMultiSystem.css'
import { getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux'
import { useMemo } from 'react'
import ContentFeaturedFeatures from '../ContentFeaturedFeatures/ContentFeaturedFeatures'
import { getLessonTypeCountMap } from '../../aux/contentProductHelpers'
import { useTranslation } from 'react-i18next'
import ContentMultiSystem from '../ContentMultiSystem/ContentMultiSystem'

const ContentPreviewDescriptionFeaturesAndMultiSystem = ({ contentPage, contentProductImgURI, contentDeviceVersionArray, contentCompatibleDeviceArray, contentStructure }) => {
    const theme = useTheme()
    const [featureKeyArray, featureFigureMap] = useMemo(() => {
        const contentPageFeaturesKeyArray = getFromSafeObject(contentPage, 'contentFeaturedFeatures.featuresKeyArray') || [];
        const contentPageFeaturesFigureMap= getFromSafeObject(contentPage, 'contentFeaturedFeatures.featuresMap') || {};
        const contentProductLessonSummaryMap = getFromSafeObject(contentStructure, 'lessonSummaryMap') || {};
        const [auxContentProductTypeLessonCountMap, numLesson] = getLessonTypeCountMap(contentProductLessonSummaryMap);
        const featuresFiguresMap = {};
        contentPageFeaturesKeyArray.forEach((featureKey, index) => {
            let featureFigure;
            if(contentPageFeaturesFigureMap.hasOwnProperty(featureKey)){
               featureFigure = contentPageFeaturesFigureMap[featureKey]; 
            }else{
                switch(featureKey){
                    case 'numDevice':
                        featureFigure = contentCompatibleDeviceArray instanceof Array ? contentCompatibleDeviceArray.length : undefined;
                        break;
                    case 'numLesson':
                        featureFigure = isNotEmptyObject(auxContentProductTypeLessonCountMap) ? numLesson : undefined ;
                        break;
                    case 'numLiveLesson':
                        featureFigure = isNotEmptyObject(auxContentProductTypeLessonCountMap) ? auxContentProductTypeLessonCountMap.live || 0 : undefined;
                        break;
                    case 'numDownloadLesson':
                        featureFigure = isNotEmptyObject(auxContentProductTypeLessonCountMap) ? auxContentProductTypeLessonCountMap.download || 0 : undefined;
                        break
                    case 'numVideoLesson':
                        featureFigure = isNotEmptyObject(auxContentProductTypeLessonCountMap) ? auxContentProductTypeLessonCountMap.video || 0 : undefined;
                        break
                }
            }
            if(featureFigure != null){
                featuresFiguresMap[featureKey] = featureFigure;
            }
        })
        return [contentPageFeaturesKeyArray, featuresFiguresMap]
    },[contentPage, contentCompatibleDeviceArray, contentStructure])
    const styles = {
        wrapper: {
            // backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0,0,0,0.2))',
            background: `linear-gradient(180deg, ${hexToRgb(contentPage.palette.primary.main)}, ${hexToRgb(theme.palette.background.dark)} 90%)`,
        }
    }
    const { t } = useTranslation('contentProduct');
    const isContentDesignedForSpecificDevices = contentDeviceVersionArray instanceof Array && contentDeviceVersionArray.length > 0;
    return (
        <div className='content-preview-and-description-wrapper' style={styles.wrapper}>
            <div className='content-preview-and-description-main-container'>
                <ContentPreview contentPage={contentPage} noVerticalPadding={true} wrapperStyle={{}}/>
                <ContentDescription contentPage={contentPage} imgURI={contentProductImgURI} noVerticalPadding={true} wrapperStyle={{}}/>
                <ContentFeaturedFeatures 
                    subtitle={contentPage.contentFeaturedFeatures.subtitle}
                    description={contentPage.contentFeaturedFeatures.description} 
                    featuresKeyArray={featureKeyArray} 
                    featureFigureMap={featureFigureMap} 
                    noVerticalPadding={true} 
                    stylesMap={{ 
                        subtitle: {color: contentPage.palette.secondary.main},
                        description: {color: contentPage.palette.primary.contrastText},
                        marker: {color: `${contentPage.palette.secondary.main} !important`} 
                    }}
                    widgetStylesMap={{
                        icon: {fill: contentPage.palette.secondary.main},
                        figure: {color: contentPage.palette.primary.contrastText},
                        container: {backgroundColor: 'transparent'}
                    }}
                />
                <ContentMultiSystem 
                    description={isContentDesignedForSpecificDevices ? getFromSafeObject(contentPage,'contentMultiSystem.description') || t("contentMultiSystem.contentAdaptedToYourSystem") : t("contentMultiSystem.contentCompatibleWithSystem")}
                    contentCompatibleDeviceArray={contentCompatibleDeviceArray} 
                    contentDeviceVersionArray={contentDeviceVersionArray}
                    subtitle={textTransform('title', isContentDesignedForSpecificDevices ? getFromSafeObject(contentPage,'contentMultiSystem.subtitle') || t("contentFeatures.adaptedToYourSystem") :  t("contentFeatures.compatibleWithYourSystem"))}
                    noVerticalPadding={true} 
                    stylesMap={{
                        subtitle: {color: contentPage.palette.secondary.main},
                        description: {color: contentPage.palette.primary.contrastText},
                        marker: {color: `${contentPage.palette.secondary.main} !important`} 
                    }}
                    widgetStylesMap={{
                        icon: {fill: contentPage.palette.primary.contrastText},
                        container: {backgroundColor: 'transparent'}
                    }}
                />
            </div>
        </div>
    )
}

export default ContentPreviewDescriptionFeaturesAndMultiSystem