import { useState, useEffect, useRef } from "react"
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Typography, Box, useMediaQuery, IconButton } from "@material-ui/core"
import { useTheme } from "@material-ui/core"
import { isNotEmptyObject, textTransform } from "../../aux/aux"
import { deleteUserPaymentMethod, getUserDefaultPaymentMethod } from "../../services/userServices"
import ButtonLoading from "../ButtonLoading/ButtonLoading"
import CheckoutBillingAddressEditPopUp from "../CheckoutBillingAddressEditPopUp/CheckoutBillingAddressEditPopUp"
import { updateUserBillingAddressAndCurrency } from "../../services/userServices"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PopUpModal from "../PopUpModal/PopUpModal"
import PopUpModalHeader from "../PopUpModalHeader/PopUpModalHeader"
import SimpleNoModalDialog from "../SimpleNoModalDialog/SimpleNoModalDialog"
import { TextField } from "@mui/material"

import './userBillingDataMenu.css'
import LoadingComponent from "../LoadingComponent/LoadingComponent"
import PopUpPaymentMethodEdit from "../PopUpPaymentMethodEdit/PopUpPaymentMethodEdit"
import PopUpDeleteSimpleDialog from "../PopUpDeleteSimpleDialog/PopUpDeleteSimpleDialog"
import { useHistory, useLocation } from "react-router-dom"
import { opacity } from "@cloudinary/url-gen/actions/adjust";

const UserBillingDataMenu = ({ userProfile, onUpdateUserBillingData }) => {
    const POP_UP_WIDTH = 380;
    const STRIPE_SETUP_INTENT_CLIENT_SECRET_QUERY_PARAM = 'setup_intent_client_secret';
    const location = useLocation();
    const theme = useTheme();
    const { t } = useTranslation('userProfile', { keyPrefix:'userBillingDataMenu' });
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const [popUpToShow, setPopUpToShow] = useState('');
    const [popUpValue, setPopUpValue] = useState('');
    const [popUpError, setPopUpError] = useState('');
    const [popUpDeleteToShow, setPopUpDeleteToShow] = useState('');
    const [popUpDeleteValue, setPopUpDeleteValue] = useState('');
    const [popUpDeleteError, setPopUpDeleteError] = useState('');
    const [isPopUpDeleLoading, setIsPopUpDeleteLoading] = useState(false);
    // null = pending to request, undefined = requesting error
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const [isPopUpLoading, setIsPopUpLoading] = useState(false)
    const abortControllerRef = useRef(null);
    const styles = {
        groupTitle:{
            fontWeight: 'bold'
        },
        value: {
            color: theme.palette.action.disabled,
            // whiteSpace: 'pre-line'
        },
        noAvailValue: {
            color: theme.palette.action.disabled,
            opacity: theme.palette.action.disabledOpacity,
            whiteSpace: 'nowrap'
        }
    }
    const billingTableValuesMap = {
        billingName:{
            label: t('billingName'),
            value: textTransform('capitalize', userProfile.billingAddressAndCurrency.billingName),
            action: (e) => showUpdatePopUp('billingName')
        },
        businessName:{
            label: t('businessName'),
            value: userProfile.billingAddressAndCurrency.businessName,
            action: undefined
        },
        vatNumber:{
            label: t('vatOrGtsNumber'),
            value: userProfile.billingAddressAndCurrency.vatOrGtsNumber ? userProfile.billingAddressAndCurrency.vatOrGtsNumber.toUpperCase() : undefined ,
            action: undefined
        },
        billingEmail:{
            label: t('billingEmail'),
            value: userProfile.billingAddressAndCurrency.billingEmail,
            action: (e) => showUpdatePopUp('billingEmail')
        },
        address:{
            label: t('address'),
            value: t('addressFormatter', { address:userProfile.billingAddressAndCurrency }),
            action: (e) => showUpdatePopUp('address'),
        },
    }

    const deleteBillingTableValuesMap = {
        paymentMethod:{
            onDelete: () => deleteUserDefaultPaymentMethod(),
            onCancel: () => {
                setPopUpDeleteToShow('');
                resetPopUpDelete();
            },
            title: `${t('common:delete')} ${t('paymentMethod')}`,
            warningMsg: `${textTransform('title', t('youAreGoingToDeleteYourPaymentMethod'))}.\n${textTransform('title', t('areYouSure'))}`,
            errorMsg: `${textTransform('title',t('cannotDeleteItNow'))}. ${textTransform('title', t('tryItLater'))}`
        }
    }

    const resetPopUpDelete = () => {
        setPopUpDeleteValue('');
        setPopUpDeleteError('');
    }

    const requestUserDefaultPaymentMethod = async(userId, signal=abortControllerRef.current.signal) => {
        try{
            const res = await getUserDefaultPaymentMethod(userId, 0, signal);
            if(!signal.aborted){
                setDefaultPaymentMethod(prev => (res.data));
            }
        }catch(error){
            if(!signal.aborted){
                setDefaultPaymentMethod(prev => (undefined));
            }
        }
    }

    const deleteUserDefaultPaymentMethod = async(userId=userProfile.id, paymentMethodId=defaultPaymentMethod.id, signal=abortControllerRef.current.signal) =>{
        try{
            setIsPopUpDeleteLoading(true)
            await deleteUserPaymentMethod(userId, paymentMethodId, 5000, signal);
            if(!signal.aborted){
                await requestUserDefaultPaymentMethod(userId, signal);
                setPopUpDeleteToShow('');
                resetPopUpDelete();
            }
        }catch(error){
            if(!signal.aborted){
                setPopUpDeleteError('paymentMethod');
            }
        }finally{
            setIsPopUpDeleteLoading(false);
        }
    }

    const onUpdateDefaultPaymentMethod = (newPaymetMethod) => {
        setDefaultPaymentMethod(newPaymetMethod);
    }

    const onUpdateBillingDetails = async(valuesToUpdate, signal=abortControllerRef.current.signal) => {
        try{
            setIsPopUpLoading(true);
            const res = await updateUserBillingAddressAndCurrency(userProfile.id, valuesToUpdate, 0, signal);
            const {
                updatedValues,
                details
            } = res.data
            const updatedUserProfileBillingData = {
                ...updatedValues,
                ...(isNotEmptyObject(details) ? { details } : {})
            }
            if(!signal.aborted){
                onUpdateUserBillingData(updatedUserProfileBillingData);
                if(popUpToShow){
                    setPopUpToShow('');
                    setPopUpValue('');
                }
            }
        }catch(error){
            if(popUpToShow !== 'address'){
                if(!signal.aborted){
                    setPopUpError(popUpToShow);
                }
            }else{
                throw error; 
            }
        }finally{
            if(!signal.aborted){
                setIsPopUpLoading(false);
            }
        }
    }

    const showUpdatePopUp = (item) => {
        setPopUpToShow(item);
        setPopUpValue(userProfile.billingAddressAndCurrency[item] || '');
    }

    const closePopUp = (e) => {
        setPopUpToShow('');
        setPopUpValue('');
    }

    const onChangePopUpValue = (e) => {
        e.preventDefault();
        setPopUpValue(e.target.value);
    }

    const getPopUpErrorMsg = (popUpToShow) => {
        return `${textTransform('title', t('cannotUpdateThisFieldNow'))}`
            +`\n${textTransform('title', t('tryItLater'))}` 
    }

    const handleOnAgreePopUp = async (e) => {
        const updatedValue = { [popUpToShow]: popUpValue }
        await onUpdateBillingDetails(updatedValue)
    }
    
    const isPopUpDisabled = (item) => {
        return !popUpValue || popUpValue === userProfile.billingAddressAndCurrency[item];
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        const query = new URLSearchParams(location.search);
        if(query.has(STRIPE_SETUP_INTENT_CLIENT_SECRET_QUERY_PARAM)){
            showUpdatePopUp('paymentMethod')
        }
        requestUserDefaultPaymentMethod(userProfile.id, abortController.signal);
        return(() => { abortController.abort() })
    },[])

    useEffect(() => {
        if(popUpError !== ''){
            const timeoutId = setTimeout(() => setPopUpError(''), 3000);
            return(() => clearTimeout(timeoutId))
        }
    },[popUpError])

    return (
        <>
            {/* POPUP BILLING ADDRESS */}
            <CheckoutBillingAddressEditPopUp 
                handleShowPopUp={[popUpToShow === 'address', ()=> setPopUpToShow(null)]} 
                updateBillingAddress={onUpdateBillingDetails} 
            />
            {/* POPUP REMAINING DETAILS */}
            <PopUpModal 
                showModal={popUpToShow && popUpToShow != 'address' && popUpToShow != 'paymentMethod'}
                onClickClose={closePopUp}
                width={POP_UP_WIDTH} 
            >
                <PopUpModalHeader title={popUpToShow && popUpToShow != 'address' && popUpToShow != 'paymentMethod' ? t(popUpToShow) : ''}/>
                <SimpleNoModalDialog
                    className='user-billing-data-menu-popup-dialog-container' 
                    onDisagree={closePopUp}
                    onAgree={handleOnAgreePopUp}
                    isDisabled={isPopUpDisabled(popUpToShow)}
                    isLoading={isPopUpLoading}
                    isError={popUpError && popUpError != 'address' && popUpError != 'paymentMethod'}
                    errorMsg={getPopUpErrorMsg(popUpToShow)}
                >
                    <TextField
                        type={ popUpToShow === "billingEmail" ? "email" : "text" }
                        variant="standard"
                        name={popUpToShow}
                        label={popUpToShow && popUpToShow != 'address' && popUpToShow != 'paymentMethod' ? textTransform('title', t(popUpToShow)) : ''}
                        value={popUpValue}
                        onChange={onChangePopUpValue}
                        fullWidth
                    />
                </SimpleNoModalDialog>
            </PopUpModal>
            {/* POPUP PAYMENT METHOD */}
            <PopUpPaymentMethodEdit 
                userId={userProfile.id}
                billingAddress={userProfile.billingAddressAndCurrency} 
                show={popUpToShow === 'paymentMethod'} 
                onClickClose={closePopUp} 
                updateDefaultPaymentMethod={onUpdateDefaultPaymentMethod}
            />
            {/* POPUP DELETE PAYMENT METHOD */}
            <PopUpDeleteSimpleDialog 
                show={popUpDeleteToShow !== ''}
                isLoading={isPopUpDeleLoading}
                onDelete={ deleteBillingTableValuesMap[popUpDeleteToShow]?.onDelete }
                onCancel={ deleteBillingTableValuesMap[popUpDeleteToShow]?.onCancel }
                title={ deleteBillingTableValuesMap[popUpDeleteToShow]?.title }
                warningMsg={ deleteBillingTableValuesMap[popUpDeleteToShow]?.warningMsg }
                isError={popUpDeleteError !== ''}
                errorMsg={ deleteBillingTableValuesMap[popUpDeleteToShow]?.errorMsg}
            />
            <div className="user-billing-data-menu-main-container">
                <Typography variant="h5" className="user-billing-data-menu-main-title">
                    {textTransform('capitalize', t('billing'))}
                </Typography>
                <div className="user-billing-data-menu-content-container">
                    <div className="user-billing-data-menu-currency-group-container">
                        <Typography variant="body1">
                            {`${textTransform('title', t('currency'))}`}
                        </Typography>
                        <Typography variant="body1" style={styles.value}>
                            {textTransform('title', userProfile.billingAddressAndCurrency.currencyId)}
                        </Typography>
                    </div>
                    <Divider variant="fullWidth" />
                    <div className="user-billing-data-payment-method-group-container">
                        <Typography variant={matchDesktop ? "body1" : "caption"}>
                            {`${textTransform('title', t('paymentMethod'))}`}
                        </Typography>
                        <div className="user-billing-data-payment-method-field-value-button-container">
                            {defaultPaymentMethod ?
                                    <Typography variant='body1' className="user-billing-data-menu-field-value" style={isNotEmptyObject(defaultPaymentMethod) ? styles.value : styles.noAvailValue} >
                                        {isNotEmptyObject(defaultPaymentMethod) ?
                                                defaultPaymentMethod.type === 'card' ? 
                                                    `${defaultPaymentMethod.brand.toUpperCase()} ${t('endsWith')} ${defaultPaymentMethod.last4}` 
                                                    : 
                                                    defaultPaymentMethod.type.toUpperCase()
                                                :
                                                textTransform('title',t('currentlyNoAvailableInfo'))
                                        }
                                    </Typography>
                                :
                                    <LoadingComponent visibleElements="circle" circleSize={theme.typography.body1.fontSize}/>
                            }
                            {matchDesktop ? 
                                <>
                                    <ButtonLoading
                                        color='secondary'
                                        label={ t('common:delete') }
                                        disabled={ !defaultPaymentMethod || !defaultPaymentMethod.hasOwnProperty('id') }
                                        onClick={(e) => {setPopUpDeleteToShow('paymentMethod')}}
                                    />
                                    <ButtonLoading
                                        color='primary'
                                        label={ t('common:update') }
                                        disabled={ !defaultPaymentMethod }
                                        onClick={(e) => {showUpdatePopUp('paymentMethod')}}
                                    />
                                </>
                                :
                                <>
                                    <IconButton
                                        color='secondary'
                                        size="small"
                                        disabled={ !defaultPaymentMethod || !defaultPaymentMethod.hasOwnProperty('id') }
                                        onClick={(e) => {setPopUpDeleteToShow('paymentMethod')}}
                                    >
                                        <DeleteIcon style={styles.icon}/>
                                    </IconButton>
                                    <IconButton
                                        color='primary'
                                        size="small"
                                        disabled={ !defaultPaymentMethod }
                                        onClick={(e) => {showUpdatePopUp('paymentMethod')}}
                                    >
                                        <EditIcon style={styles.icon} />
                                    </IconButton>

                                </>
                            }
                        </div>
                    </div>
                    <Divider variant="fullWidth" />
                    <div className="user-billing-data-menu-group-container">
                        <Typography variant="body1" style={styles.groupTitle}>
                            {textTransform('title', t('details'))}
                        </Typography>
                        {Object.entries(billingTableValuesMap).map(([item, itemDetails], index) => {
                            return(
                                <div key={index} className="user-billing-data-menu-inline-field-group-container">
                                    <Typography variant={matchDesktop ? "body1" : "caption"}>
                                        {`${textTransform('title', itemDetails.label)}`}
                                    </Typography>
                                    <div className="user-billing-data-menu-field-value-button-container">
                                        <Typography variant='body1' className='user-billing-data-menu-field-value' style={itemDetails.value || itemDetails.action ? styles.value : styles.noAvailValue}>
                                            {itemDetails.value || itemDetails.action ? 
                                                itemDetails.value
                                                :
                                                textTransform('title',t('currentlyNoAvailableInfo'))
                                            }
                                        </Typography>
                                        {matchDesktop ? 
                                            <ButtonLoading
                                                color='primary'
                                                disabled={ !itemDetails.action }
                                                label={ t('common:update') }
                                                onClick={itemDetails.action}
                                            />
                                            :
                                            <IconButton
                                                color='primary'
                                                size="small"
                                                disabled={ !itemDetails.action }
                                                onClick={itemDetails.action}
                                            >
                                                <EditIcon style={styles.icon} />
                                            </IconButton>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Divider variant="fullWidth" />
                    <div className="user-billing-data-menu-group-container">
                        <Typography style={styles.groupTitle}>
                            {textTransform('title', t('invoices'))}
                        </Typography>
                        <Typography variant='body1' className="user-billing-menu-field-value" style={styles.noAvailValue}>
                            {textTransform('title', t('currentlyNoAvailableInfo'))}
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserBillingDataMenu
