import { Grid, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import UserSystemSelectFormItem from '../UserSystemSelectFormItem/UserSystemSelectFormItem';
import ToggleMsg from '../ToggleMsg/ToggleMsg';
import './defaultUserSystemSelect.css';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

const DefaultUserSystemSelect = ({ defaultSystem, contentAvailableConfiguration, onAgree, onDisagree, onFinish, onRender }) => {
    const { t } = useTranslation('common', { keyPrefix: "defaultUserSystemSelect" });
    const [isUpdating, setIsUpdating] = useState(false);
    const abortControllerRef = useRef(null);
    const [isContentCompatible, setIsContentCompatible] = useState(null);
    const [isContentVersioned, setIsContentVersioned] = useState(null);
    const [error, setError] = useState({isError:false, msg:undefined});
    const handleOnYes = async(e) => {
        e.preventDefault();
        try{
            setIsUpdating(true);
            const updatedValues = {
                selectedDeviceName : defaultSystem.deviceName,
                selectedSoftwareName : defaultSystem.softwareName,
                selectedUserSystemId : defaultSystem.id
            };
            await onAgree(updatedValues);
            onFinish()
        }catch(error){
            if(!abortControllerRef.current.signal.aborted){
                setError({isError:true, msg:`${textTransform('title', t('errorSettingDefaultUserSystem'))}. ${textTransform('title', t('selectAnotherOrYouCanUpdateLater'))}` })
            }
        }finally{
            if(!abortControllerRef.current.signal.aborted){
                setIsUpdating(false)
            }
        }
    };

    
    useEffect(() => {
        abortControllerRef.current = new AbortController();
        return(() => {
            abortControllerRef.current.abort();
        })
    },[])

    useEffect(() => {
        let isContentCompatible = null;
        let isContentVersioned = null;
        if(isNotEmptyObject(defaultSystem)){
            if(isNotEmptyObject(contentAvailableConfiguration)){
                const softwareArray = getFromSafeObject(contentAvailableConfiguration, 'softwareArray', []);
                const deviceArray = getFromSafeObject(contentAvailableConfiguration, 'deviceArray', []);
                const versionedSystemArray = getFromSafeObject(contentAvailableConfiguration, 'systemLessonVersionArray', []);
                let softwareAvail = softwareArray.find(software => software.softwareName.toLowerCase() === defaultSystem.softwareName.toLowerCase()) != null;
                let deviceAvail = deviceArray.find(device => device.deviceName.toLowerCase() === defaultSystem.deviceName.toLowerCase()) != null;
                isContentCompatible = softwareAvail && deviceAvail;
                isContentVersioned = versionedSystemArray.find(versionedSystem => versionedSystem.deviceName.toLowerCase() === defaultSystem.deviceName.toLowerCase() && versionedSystem.softwareName.toLowerCase() === defaultSystem.softwareName.toLowerCase()) != null
            }
        }
        setIsContentCompatible(isContentCompatible);
        setIsContentVersioned(isContentVersioned)
    },[defaultSystem, contentAvailableConfiguration])

    useEffect(() => {
        const timeoutId = setTimeout(onRender, 300);
        return(() => clearTimeout(timeoutId))
    })

    return (
        isContentCompatible != null && isContentVersioned != null ?
            <Grid container direction='column'>
                <Grid item>
                    <Typography variant='body1'>
                        {textTransform('title', t(isContentCompatible && isContentVersioned ? "thiContentIsDesignedForYourDefaultSystem" : "thisIsYourDefaultSystem"))}
                    </Typography>
                </Grid>
                <Grid item className='default-user-system-select-item'>
                    <div style={{border:"solid 1px #888888", borderRadius:"5px", padding:"5px"}}>
                        <UserSystemSelectFormItem system={defaultSystem} isCompatible={isContentCompatible} isVersioned={isContentVersioned} showIsSelectedWhenNickName={false} />
                    </div>
                </Grid>
                <Grid item className='default-user-system-select-item'>
                    <Typography variant='body1'>
                        {isContentCompatible ? 
                                textTransform('title', t("doYouwantToSelectDefaultUserSystem"))
                            :
                                textTransform('title', t("thisContentIsNotAdapted"))
                        }
                    </Typography>
                </Grid>
                <Grid item className='default-user-system-select-item'>
                    <Grid container justifyContent='flex-end'>
                        <Grid item className='default-user-system-select-button'>
                            <Button disabled={isUpdating} onClick={onDisagree} color='primary'>
                                {t("selectAnother")}
                            </Button>
                        </Grid>
                        {isContentCompatible &&
                            <Grid item className='default-user-system-select-button'>
                                <ButtonLoading onClick={handleOnYes} disabled={isUpdating || error.isError} isLoading={isUpdating} label={t("common:yes")} 
                                color="primary" fullWidth/>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <ToggleMsg 
                    isShown={error.isError} 
                    msg={error.msg} 
                    type='error' 
                />
            </Grid>
            :
            <LoadingComponent visibleElements='circle'/>
    )
}

export default DefaultUserSystemSelect