
import { textTransform, textTruncate } from '../../aux/aux';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import DevicesIcon from '@mui/icons-material/Devices';
import WebIcon from '@mui/icons-material/Web';
import Circle from '../../img/circle.svg';
import './userSystemSelectFormItem.css'
import ContentSystemCompatibilityLabel from '../ContentSystemCompatibilityLabel/ContentSystemCompatibilityLabel';

const UserSystemSelectFormItem = ({system, isSelected, isCompatible=false, isVersioned=false, showNickName=true, showIsSelectedWhenNickName=true, showCompatibilityLabel=true}) => {
    const TEXT_TRUNCATE_LENGTH_LONG = 35;
    const TEXT_TRUNCATE_LENGTH_MEDIUM = 20;
    const TEXT_TRUNCATE_LENGTH_SHORT = 10;
    const TEXT_TRUNCATE_END = '...';
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'userSystemSelectFormItem' });

    return (
        <Grid container direction='column'>
            {(showIsSelectedWhenNickName || showNickName || showCompatibilityLabel) ?
                <Grid item>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item xs>
                            {showNickName &&
                                <Grid container alignItems='center'>
                                        <Grid item>
                                            <Typography variant='body1'>
                                                {textTransform('title', textTruncate(system.nickname, TEXT_TRUNCATE_LENGTH_LONG, TEXT_TRUNCATE_END))}
                                            </Typography>
                                        </Grid>
                                        {showIsSelectedWhenNickName ?
                                            <Grid item className='user-system-select-form-item-icon'>
                                                {isSelected &&
                                                    <Circle height={'0.5em'} fill={theme.palette.primary.main} display={"block"}/>
                                                }
                                            </Grid>
                                            :
                                            null
                                        }
                                </Grid>
                            }
                        </Grid>
                        {showCompatibilityLabel ?
                            <Grid item>
                                <ContentSystemCompatibilityLabel isVersioned={isVersioned} isCompatible={isCompatible} />
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                </Grid>
                :
                null
            }
            <Grid item>
                <Grid container justifyContent='space-evenly'>
                    <Grid item >
                        <Grid container alignContent='center'>
                            <Grid item className='user-system-select-form-item-icon'>
                                <Typography variant='body1'>
                                    <DevicesIcon fontSize='inherit'/>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>
                                    {textTruncate(system.deviceName, TEXT_TRUNCATE_LENGTH_SHORT, TEXT_TRUNCATE_END).toUpperCase()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid> 
                    <Grid item >
                        <Grid container alignContent='center'>
                            <Grid item className='user-system-select-form-item-icon'>
                                <Typography variant='body1'>
                                    <WebIcon fontSize='inherit'/>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>
                                    {textTruncate(system.softwareName, TEXT_TRUNCATE_LENGTH_SHORT, TEXT_TRUNCATE_END).toUpperCase()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid> 
                </Grid>
            </Grid>
        </Grid>
  )
}

export default UserSystemSelectFormItem