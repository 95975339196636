import { Box, List, ListItem, Typography, styled } from '@material-ui/core'
import parse, { domToReact, attributesToProps } from 'html-react-parser'
import { useMemo } from 'react'
import CustomLi from '../CustomLi/CustomLi'
/**
 * Accept a string with the following tags
 *  ul, li, b, i, u, s, h, span
 *  ul: can only contain an array of li tags inside. 
 *      It is replaced by <List> value </List>
 *  li: can only contain text. Another ul tag inside is not allowed (nested lists are not allowed)
 *      It is replaced by <ListItem><Typography> value </Typography></ListItem>
 *  b,i,u,s,h,span: are only applied to text
 *      They are replaced by <Box component='span'> value </Box>
 *  Note: the text not surrounding by tag is enclosed between <Typography> value </Typography> tags
 * @param {*} param0 
 * @returns 
 */

const StyledHTMLString = ({children, typographyProps={}, ulProps={}, liProps={ marker: {} }}) => {    
    const childrenWithPTags = useMemo(() => {
        const wrapTextWithPTag = (text, htmlTag='ul') => {
            const splitStringByRegExMatchArray = (input, regExMatch) => {
                const result = [];
                let lastIndex = 0;
                let inputString = input;
                if(regExMatch){
                    [...regExMatch].forEach(match => {
                        const startMatchIndex = match.index;
                        let previousToken = inputString.slice(lastIndex, startMatchIndex);
                        const matchedToken = match[0];
                        if(previousToken !== ''){
                            previousToken = `<p>${previousToken}</p>`
                            result.push(previousToken);
                        }
                        result.push(matchedToken);
                        lastIndex = startMatchIndex + matchedToken.length;
                    })
                }
                if(lastIndex < (inputString.length - 1)){
                    let lastToken = inputString.slice(lastIndex);
                    lastToken = `<p>${lastToken}</p>`
                    if(lastToken !== ''){
                        result.push(lastToken);
                    }
                }
                return result;
            }
            if(text){        
                const listRegEx = new RegExp(`(<${htmlTag}\\b[^>]*>(?:[\\s\\S]*?)<\/${htmlTag}>)`, 'gm');
                const listMatch = text.matchAll(listRegEx);
                return splitStringByRegExMatchArray(text, listMatch).join(''); 
            }else{
                return '';
            }
        }
        return wrapTextWithPTag(children)
    },[children])

    const defaultStyle = {
        whiteSpace: 'pre-line'
    }
    const options = {
        replace: (domNode, index) => {
            if(domNode.type === 'tag'){
                const elemProps = attributesToProps(domNode.attribs); 
                let componentType;
                switch(domNode.name){
                    case 'p':
                        componentType = 'p';
                        break;
                    case 'ul':
                        componentType = 'ul';
                        elemProps.className = `styled-list${elemProps.className ? ` ${elemProps.className}` : ''}`;
                        break;
                    case 'li':
                        componentType = 'li';
                        elemProps.className = `styled-list-item`
                        break;
                    case 'b':
                        elemProps.fontWeight = "bold";
                        componentType = 'span';
                        break;
                    case 'i':
                        elemProps.fontStyle = "italic";
                        componentType = 'span';
                        break;
                    case 'u':
                        elemProps.style={ textDecoration: "underline" }
                        componentType = 'span';
                        break;
                    case 's':
                        elemProps.style={ textDecoration: "line-through" }
                        componentType = 'span';
                        break;
                    case 'h':
                        elemProps.className = `highlight${elemProps.className ? ` ${elemProps.className}` : ''}`
                        componentType = 'span';
                    case 'span':
                        componentType = 'span';
                        break;
                }
                let Component
                switch(componentType){
                    case 'p':
                        Component = <Typography {...elemProps} {...typographyProps} style={{...typographyProps.style, ...defaultStyle}}>{domToReact(domNode.children, options)}</Typography>; 
                        break;
                    case 'ul':
                        Component = <ul  {...elemProps} {...ulProps}>{domToReact(domNode.children, options)}</ul>;
                        break;
                    case 'li':
                        Component = <CustomLi {...elemProps} {...liProps}><Typography {...typographyProps} style={{...typographyProps.style, ...defaultStyle}}>{domToReact(domNode.children, options)}</Typography></CustomLi>;
                        break;
                    default:
                        Component = <Box component={componentType} {...elemProps}>{domToReact(domNode.children, options)}</Box>
                }
                return Component;
            }
        }
    }
    return (
        typeof childrenWithPTags === "string" ? <>{parse(childrenWithPTags, options)}</> : null
    )
}

export default StyledHTMLString