import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LandingHero from '../../components/LandingHero/LandingHero'
import LandingBrands from '../../components/LandingBrands/LandingBrands'
import LandingFeatures from '../../components/LandingFeatures/LandingFeatures'
import LandingValuePropositions from '../../components/LandingValuePropositions/LandingValuePropositions'
import LandingFeaturedContent from '../../components/LandingFeaturedContent/LandingFeaturedContent'
import LandingValueInFigures from '../../components/LandingValueInFigures/LandingValueInFigures'
import LandingOurContent from '../../components/LandingOurContent/LandingOurContent'
import LandingHowItWorks from '../../components/LandingHowItWorks/LandingHowItWorks'
import LandingSocial from '../../components/LandingSocial/LandingSocial'
import LandingCenteredCTA from '../../components/LandingCenteredCTA/LandingCenteredCTA'
import LandingFAQ from '../../components/LandingFAQ/LandingFAQ'
import ContactSupportSection from '../../components/ContactSupportSection/ContactSupportSection'
import MailingListSubscriptionSection from '../../components/MailingListSubscriptionSection/MailingListSubscriptionSection'
// import BigFooter from '../../components/BigFooter/BigFooter'
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks'
import LandingHeroMessage from '../../components/LandingHeroMessage/LandingHeroMessage'
import { useDisplayablePromoCodeCtx } from '../../customHooks/useDisplayablePromoCodeCtx'
import { getFromSafeObject, getSafeDateOrUndefined, isNotEmptyObject } from '../../aux/aux'
import { isPromoCodeDisplayableEveryWhere } from '../../aux/displayablePromoCodeHelpers'
import './landing.css'
import BannerPromo from '../../components/BannerPromo/BannerPromo';

const Landing = ({ user, setUser, promoContainerRef=null }) => {
    const { t } = useTranslation('landing');
    const displayablePromoCodeCtx = useDisplayablePromoCodeCtx();
    const [activeOpenPromo, setActiveOpenPromo] = useState(null)
    const [showPromo, setShowPromo] = useState(false);

    const handleClosePromo = (e) => {
        e.preventDefault();
        setShowPromo(false);
    }
    
    useEffect(() => {
        let activePromoCode = null
        if(isNotEmptyObject(displayablePromoCodeCtx)){
            const isDisplayable = isPromoCodeDisplayableEveryWhere(displayablePromoCodeCtx);
            if(isDisplayable){
                activePromoCode = displayablePromoCodeCtx;
            }
        }
        setActiveOpenPromo(activePromoCode);
    },[displayablePromoCodeCtx])

    useEffect(() => {
        if(activeOpenPromo && promoContainerRef && promoContainerRef.current != null){
            setShowPromo(true);
        }else{
            setShowPromo(false);
        }
    },[activeOpenPromo])

    return (
        <div className='landing-main-container'>
            <LandingHero user={user} setUser={setUser} />
            <LandingBrands />
            <LandingFeatures />
            <LandingValuePropositions />
            <LandingFeaturedContent />
            <LandingValueInFigures />
            <LandingOurContent user={user} />
            <LandingHowItWorks/>           
            <LandingHeroMessage/>
            <LandingSocial/>
            <LandingCenteredCTA user={user} setUser={setUser} />
            <LandingFAQ />
            <ContactSupportSection
                user={user} 
                categoryObject={{
                    categoryArray: ['admissions', 'contentsAndPrograms', 'productsAndPrices', 'subscriptions', 'other'],
                    defaultKey: 'contentsAndPrograms'
                }}
            />
            <MailingListSubscriptionSection 
                user={user} 
                subscriptionListArray={[
                    {
                        name:'Newsletter', customSource:'landing-form', isRequired:true
                    },
                    {
                        name:'Marketing', customSource:'landing-form',
                    },
                ]}
                translations={{
                    title: '',
                    subtitle: t('landingMailingListSubscription.trySomeOfOurClasses'),
                    description: t('landingMailingListSubscription.landingMailingSubscriptionMessage'),

                }}
            />
            {activeOpenPromo && showPromo ?
                ReactDOM.createPortal(
                    <BannerPromo 
                        promo={activeOpenPromo}
                        txt={{
                            name: `${getFromSafeObject(activeOpenPromo,'details.occurrence.details.displayability.description.title')} ${t('symbolParty')}`,
                            description: getFromSafeObject(activeOpenPromo,'details.occurrence.details.displayability.description.description'),
                        }}
                        endsDate={getSafeDateOrUndefined(getFromSafeObject(activeOpenPromo,`details.occurrence.endsDate`))}
                        onClose={handleClosePromo}
                    />
                , promoContainerRef.current)
                :
                null
            }
            <BigFooterNoLinks user={user} />
            {/* <BigFooter /> */}
        </div>
    )
}

export default Landing