import { createContext, useEffect, useRef, useState } from "react"
import { createUserFavoriteProductArray, createUserProductNotificationArray, getUserFavoriteProductArray, getUserProductNotificationArray, removeUserFavoriteProductArray, removeUserProductNotificationArray } from "../services/userServices";
import { getFromSafeObject, isNotEmptyObject, textTransform } from "../aux/aux";
import PopUpLogIn from "../components/PopUpLogIn/PopUpLogIn";
import { useTranslation } from "react-i18next";
import PopUpSimpleFeedback from "../components/PopUpSimpleFeedback/PopUpSimpleFeedback";
import { Typography } from "@material-ui/core";

const UserProductNotificationContext = createContext(null);
const UpdateUserProductNotificationContext = createContext(null);

const UserProductNotificationProvider = ({ user, setUser, children }) => {
    const { t } = useTranslation('common', { keyPrefix: 'userProductNotificationProvider'});
    const [userProductNotificationMap, _setUserProductNotificationMap] = useState(null);
    const [showLogInSignInPopUp, setShowLogInSignInPopUp] = useState(false);
    const [updatePopUp, setUpdatePopUp] = useState({ show:false, msg:undefined, type:undefined });
    const userProductNotificationMapRef = useRef(null);

    const setUserProductNotification = async (type, productIdentifier, notify, context={}, createdAt=new Date(), verboseError=true) => {
        const userId = getFromSafeObject(user, 'basicData.id')
        if(userId){
            try{
                if(userProductNotificationMapRef.current !== null){
                    if(productIdentifier.productId != null){
                        const updateUserProductNotificationBackend = notify ? createUserProductNotificationArray : removeUserProductNotificationArray
                        const productNotification = { ...productIdentifier, notificationType:type, notificationContext:context, createdAt};
                        await updateUserProductNotificationBackend(userId, [ productNotification ], 0);
                        _setUserProductNotificationMap(prev => {
                            let newMap = { ...prev }
                            if(notify){
                                newMap[type] = { ...(prev[type] || {}), [productIdentifier.productId]: productNotification };
                            }else{
                                delete newMap[type][productIdentifier.productId]
                            }
                            return newMap
                        });
                        let msg;
                        if(notify){
                            switch(type){
                                case 'isReleased':
                                    msg = t('notifyYouWhenTheProductIsReleased');
                                    break
                                case 'isConsumable':
                                    msg = t('notifyYouWhenTheProductIsConsumable');
                                    break
                            }
                            msg += `. ${t('thankYou')}`;
                        }else{
                            msg = `${t('canceledYourNotification')}. ${t('thankYou')}`
                        }
                        if(msg){
                            setUpdatePopUp(prev => ({...prev, show:true, type:'success', msg }));
                        }
                    }else{
                        throw new Error('no valid productId is given')
                    }
                }else{
                    throw new Error('request needs to be done before set a userProductNotification')
                }
            }catch(error){ 
                const msg = `${t('cannotSetYourNotificationNow')}. ${t('tryItLater')}`
                setUpdatePopUp(prev => ({...prev, show:true, type:'error', msg }));
            }
        }else{
            setShowLogInSignInPopUp(true);
        }
    }

    const requestUserProductNotificationArray = async(productIdentifierArray, signal=null) => {
        try{
            const userId = getFromSafeObject(user, 'basicData.id');
            if(userId){
                if(productIdentifierArray.length > 0){
                    const res = await getUserProductNotificationArray(userId, productIdentifierArray, 0, signal);
                    const {
                        userProductNotificationArray
                    } = res.data
                    if(!signal.aborted){
                        _setUserProductNotificationMap(prev => {
                            const newMap = userProductNotificationArray.reduce((map, userProductNotification) => {
                                const typeMap = map[userProductNotification.notificationType] || {};
                                typeMap[userProductNotification.productId] = userProductNotification;
                                map[userProductNotification.notificationType] = typeMap;
                                return map
                            }, prev || {});
                            return newMap;
                        })
                    }
                }
            }
        }catch(error){
            // TODO: implement
            console.log(error)
        }
    }

    const isUserProductNotificationSet = (type, productIdentifier) => {
        return isNotEmptyObject(userProductNotificationMap) && isNotEmptyObject(productIdentifier) ? getFromSafeObject(userProductNotificationMap, `${type}.${productIdentifier.productId}`) : false;
    }

    useEffect(() => {
        if(!user){
            _setUserProductNotificationMap(null);
        }
    },[user])

    useEffect(() => {
        userProductNotificationMapRef.current = userProductNotificationMap
    },[userProductNotificationMap])

    return(
        <UserProductNotificationContext.Provider value={{isUserProductNotificationSet, userProductNotificationMap}}>
            <UpdateUserProductNotificationContext.Provider value={{requestUserProductNotificationArray, setUserProductNotification}}>
                <>
                    <PopUpLogIn setUser={setUser} setShowModal={setShowLogInSignInPopUp} showModal={showLogInSignInPopUp} passiveVerification={process.env.REACT_APP_TARGET_ENV === 'staging'}/>
                    <PopUpSimpleFeedback show={updatePopUp.show} animationType={updatePopUp.type} onClickClose={() => setUpdatePopUp(prev => ({...prev, show:false}))} >
                        <Typography variant="body1">
                            {updatePopUp.msg}
                        </Typography>
                    </PopUpSimpleFeedback>
                    { children }
                </>
            </UpdateUserProductNotificationContext.Provider>
        </UserProductNotificationContext.Provider>
    )
}

export { UserProductNotificationProvider , UserProductNotificationContext, UpdateUserProductNotificationContext }