import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/styles'
import { Button, Typography, useMediaQuery } from '@material-ui/core'
import { textTransform } from '../../aux/aux'
import PopUpContactSupport from '../PopUpContactSupport /PopUpContactSupport'
import './contactSupportSection.css'
import SocialProfileBadge from '../SocialProfileBadge/SocialProfileBadge';

const ContactSupportSection = ({ user, categoryObject={ defaultKey:undefined, categoryArray: undefined }, contactByForm=true, supportContext={} }) => {
    const POP_UP_WIDTH = 380; //450px
    const BADGE_SIZE_PX = 64;
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'contactSupportSection'});
    const matchMobile = useMediaQuery('(max-width:680px)')
    const [showPopup, setShowPopup] = useState(false);
    const suportImgURLMap = {
        'left': '/img/support-profile-1_300x300.jpg',
        'right': '/img/support-profile-2_300x300.jpg',
        'center': '/img/support-profile-3_300x300.jpg',
    }
    const styles = {
        wrapper:{
            backgroundColor: theme.palette.primary.lighter, 
        },
        message: {
            whiteSpace: 'pre-line'
        },
        title: {
            fontWeight: 'bold'
        }
    }

    const handleOnShowEmailClient = () => {
        const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL
        const query = new URLSearchParams({
            subject: t('emailSubjectPlaceholder'),
            // body: ''
        })
        const url = new URL(`mailto:${SUPPORT_EMAIL}?${query.toString()}`, );
        window.open(
            url,
            'emailWindow'
        )
    }

    const handleOnShowForm = () => {
        setShowPopup(prev => !prev);
    }

    const handleOnClick = () => {
        if(contactByForm){
            handleOnShowForm()
        }else{
            handleOnShowEmailClient()
        }

    }
    
    return (
        <div className='contact-support-section-wrapper' style={styles.wrapper}>
            {showPopup ?
                <PopUpContactSupport 
                    user={user} 
                    showModal={showPopup} 
                    onClickClose={handleOnShowForm} 
                    width={POP_UP_WIDTH}
                    categoryObject={categoryObject}
                    supportContext={supportContext}
                />
                :
                null
            }
            <div className='contact-support-section-img-container'>
                <SocialProfileBadge url={suportImgURLMap.left} diameterPx={BADGE_SIZE_PX} className='contact-support-section-back-img'/>
                <SocialProfileBadge url={suportImgURLMap.right} diameterPx={BADGE_SIZE_PX} className='contact-support-section-back-img'/>
                <SocialProfileBadge url={suportImgURLMap.center} diameterPx={BADGE_SIZE_PX} className='contact-support-section-front-img'/>
            </div>
            <div className='contact-support-section-text-container'> 
                <Typography variant={ matchMobile ? 'h6' : 'h6'} style={styles.title}>
                    {t('stillHaveQuestions')}
                </Typography>
                <Typography variant='body1' style={styles.message}>
                    {
                        `${t('notFindTheQuestionYouAreLookingFor')}\n`+
                        `${t('ourTeamCanHelp')}`
                    }
                </Typography>
            </div>
            <div className='contact-support-section-button-container'>
                <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    onClick={handleOnClick}
                >
                    {t('common:sendUsAMessage')}
                </Button>
            </div>
        </div>
    )
}

export default ContactSupportSection