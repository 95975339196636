import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/styles';
import { Chip, Typography, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import AccumulatedVideoIcon from '../../img/accumulated-video-icon.svg'
import AccumulatedLessonIcon from '../../img/accumulated-lesson-icon.svg'
import DivLazyLoad from '../DivLazyLoad/DivLazyLoad';
import { getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import { getFilteredProductImageIndexOrUndefined } from '../../aux/productHelpers';

import ImgDN from "../../cdn/imgDN";
import cloudinary from "../../cdn/providers/cloudinary";

import './contentFixedSizeSliderItem.css'
import { useDetectDrag } from '../../customHooks/useDetectDrag';
import ConsumableDateChip from '../ConsumableDateChip/ConsumableDateChip';
import ConsumabilityStatusChip from '../ConsumabilityStatusChip/ConsumabilityStatusChip';
import SelectedProductTypeChip from '../SelectedProductTypeChip/SelectedProductTypeChip';
import { contentProfessorArrayToString, getMainProfessorOrNull } from '../../aux/contentProductHelpers';

const ContentFixedSizeSliderItem = ({ content, onClick, lazyLoadThreshold='600px' }) => {
    const theme = useTheme();
    const {t, i18n} = useTranslation('common');
    const WRAPPER_HEIGHT_PX = 454;
    const WRAPPER_WIDHT_PX = 280; // official width = 304px
    const matchMobile = useMediaQuery('(max-width:680px)');
    const assetProvider = new ImgDN(cloudinary);
    const [backgroundImage, setBackgroundImage] = useState(undefined);
    const [mainProfessor, setMainProfessor] = useState(undefined);
    const [isDragged, setDragRef] = useDetectDrag(true);
    const styles = {
        wrapper:{
            height: `${WRAPPER_HEIGHT_PX}px`,
            width: `${WRAPPER_WIDHT_PX}px`,
            backgroundColor: theme.palette.background.dark,
            backgroundImage:`linear-gradient(0deg, rgba(0,0,0,0.1), rgba(0,0,0,0.1))`,
            backgroundPosition: 'center',
            boxShadow: theme.shadows[2] // applied by design: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        },
        // skeleton:{
        //     height: `${WRAPPER_HEIGHT_PX}px !important`,
        //     width: `${WRAPPER_WIDHT_PX}px !important`,
        // },
        chip:{
            backgroundColor: theme.palette.text.contrast
        },
        infoContainer:{
            backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 50%, ${theme.palette.background.dark} 70%)`
        },
        title:{
            color: theme.palette.text.contrast,
            textAlign: 'center',
            fontWeight:'bold'
        },
        divider:{
            backgroundColor: theme.palette.dividerOnDark, //grey or secondary
        },
        shortDescription:{
            color: theme.palette.text.contrast
        },
        icon:{
            display:'block',
            fill: theme.palette.text.contrast
        },
        feature:{
            color: theme.palette.text.contrast
        },
        author:{
            color: theme.palette.grey[400],
            textAlign: 'center',
        },
    }
    const roundDecimalToStringWithSymbol = (num) => {
        return `${num - parseInt(num) > 0 ? '+' : ''} ${parseInt(num)}`
    }

    const handleOnClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(content);
    }

        
    // let dragTarget;
    // const dragMove = (e) => {
    //     if(dragTarget){
    //         e.preventDefault();
    //         e.stopPropagation();
    //         dragTarget.classList.add("dragging");
    //     }
    // };
    // const dragStop = (e) => {
    //     dragTarget.classList.remove("dragging");
    //     dragTarget = undefined;
    //     window.removeEventListener('pointerup', dragStop);
    //     window.removeEventListener('pointermove', dragMove);
    // };
    // const dragStart = (e) => {
    //     dragTarget = e.currentTarget;
    //     window.addEventListener('pointerup', dragStop)
    //     window.addEventListener('pointermove', dragMove)
    // }

    useEffect(() => {
        const contentDetails = content?.details;
        if(isNotEmptyObject(contentDetails)){
            const imageArray = getFromSafeObject(content, 'details.product.imageDetails.others');
            if(imageArray){
                const filterArray = [ 'imageTypeName = background', 'aspectRatio < 1' ];
                const imageIndex = getFilteredProductImageIndexOrUndefined(imageArray, filterArray)
                const image = imageArray[imageIndex];
                const imageTransformation = {}; //{ height: WRAPPER_HEIGHT_PX, width: WRAPPER_WIDHT_PX};
                let imageUrl = image ? assetProvider.getAssetUrl(image.publicId, imageTransformation) : undefined
                setBackgroundImage(imageUrl);
            }else{
                setBackgroundImage(null)
            }
            const professorArray  = contentDetails.professorArray || [];
            const mainProfessor = getMainProfessorOrNull(professorArray);
            setMainProfessor(mainProfessor);
        }else{
            setBackgroundImage(undefined)
            setMainProfessor(undefined);
        }
    },[content])

    return (
        isNotEmptyObject(content) ?
            <DivLazyLoad 
                ref={setDragRef}
                className='content-slider-item-wrapper' 
                style={styles.wrapper} 
                onClick={handleOnClick} 
                backgroundImage={backgroundImage} 
                threshold={lazyLoadThreshold}
            >
                <div className='content-slider-item-chip'>
                    <SelectedProductTypeChip selectedProductTypeName={getFromSafeObject(content, 'details.selected.selectedProductTypeName')}/>
                </div>
                <div className='content-slider-item-info-container' style={styles.infoContainer}>
                    <div className='content-slider-item-info-title'>
                        <Typography variant={matchMobile ? 'h6' : 'h5'}  style={styles.title}>
                            {textTransform('title',content.details.product.description.nameTranslated)}
                        </Typography>
                        {mainProfessor ? 
                            <Typography variant={'body2'} style={styles.author}>
                                {textTransform('capitalize', mainProfessor.fullName)}
                            </Typography>
                            :
                            null
                        }
                    </div>
                    <div className='content-slider-item-info-divider' style={styles.divider}/>
                    <div className='content-slider-item-info-short-description'>
                        <Typography variant='body2'  style={styles.shortDescription}>
                            {textTransform('title',content.details.product.description.description)}
                        </Typography>
                    </div>
                    <div className='content-slider-item-info-feature-container'>
                        {content.hasOwnProperty('lengthInMin') &&
                            <div className='content-slider-item-info-feature-item'>
                                <AccumulatedVideoIcon className='content-slider-item-info-feature-icon' style={styles.icon} />
                                <Typography variant='body2'style={styles.feature}>
                                    {`${roundDecimalToStringWithSymbol(Number(content.lengthInMin)/60)} ${t('hour', { count: Number(content.lengthInMin)/60 })}`}
                                </Typography>
                            </div>
                        }
                        {content.hasOwnProperty('numLesson') &&
                            <div className='content-slider-item-info-feature-item'>
                                <AccumulatedLessonIcon className='content-slider-item-info-feature-icon' style={styles.icon} />    
                                <Typography variant='body2' style={styles.feature}>
                                    {`${roundDecimalToStringWithSymbol(Number(content.numLesson))} ${t('lesson', { count: Number(content.numLesson)})}`}
                                </Typography>
                            </div>
                        }
                    </div>
                </div>
            </DivLazyLoad>
        :
            <Skeleton variant='rectangular' className='content-slider-item-skeleton' height={WRAPPER_HEIGHT_PX} width={WRAPPER_WIDHT_PX}/>
    )
}

export default ContentFixedSizeSliderItem