
import { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// Queries
import { backUpUserConfig, backUpUserProgress, getContentProductStructure, getUserContentConfig, getUserContentProgress } from '../../services/contentServices';

// Helpers
import { getProductSummary } from '../../services/visitorServices';
import { getFromSafeObject, getInsertableDate, isEmptyObject, isNotEmptyObject, isValidDate } from '../../aux/aux';

//Img and Icons
import BigFooter from '../../components/BigFooter/BigFooter';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

// Style
import './classroom.css';
import ResourcePageError from '../../components/PageError/ResourcePageError';
import ClassroomSideColumn from '../../components/ClassroomSideColumn/ClassroomSideColumn';
import ClassroomMainColumn from '../../components/ClassroomMainColumn/ClassroomMainColumn';
import PopUpClassContentFirstUserConfig from '../../components/PopUpClassContentFirstUserConfig/PopUpClassContentFirstUserConfig';
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';
import { useRedirect } from '../../customHooks/useRedirect';
import { useTranslation } from 'react-i18next';
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { sameSystems } from '../../aux/contentProductHelpers';
import StyledTypography from '../../components/StyledTypography/StyledTypography';
import {Alert} from '@mui/material';

const Classroom = ({ contentProductId, user, addMessages }) => {
    /** 
    * The URI '/learn/courseId give us the courseId to fetch data from the Db
    */
    const ERROR_PATH_TO_REDIRECT = `/${process.env.REACT_APP_CLASS_SUBDOMAIN}/my-content`;
    const { t } = useTranslation('classroom');
    const theme = useTheme();
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const redirectApi = useRedirect();
    const localeCtx = useLocaleCtx();
    const [userConfig, setUserConfig] = useState(null);
    const [userProgress, setUserProgress] = useState(null);
    const [contentSummary, setContentSummary] = useState(null);
    const [isOpenFirstConfigModal, setIsOpenFirstConfigModal] = useState(false);
    const [userAccessDetails, setUserAccessDetails] = useState(null);
    const [contentSelected, setContentSelected] = useState(null); // Id from the content selected by the user
    const [contentStructure, setContentStructure] = useState(null);
    const [contentNav, setContentNav] = useState(null);
    const [retrievalDetails, setRetrievalDetails] = useState(null);
    const [showRetrievalDetails, setShowRetrievalDetails] = useState(false);
    const [requestingDataStatus, setRequestingDataStatus] = useState({
        details:{
            // userProgress:false,
            // userAccessDetails:false,
            // contentStructure:false,
            // contentSummary:false,
        },
        errorDetails:{}
    })
    const [classroomState, setClassroomState] = useState({
        state: 'mounting', // ['mounting', 'requestingData', 'ready',  'error']
        details:{
            isRequestingData:false,
            isContentAvailable:false,
            isClassroomReady:false,
            isError:false,
        },
        errorDetails:{
            noAvail: false,
            requestingDataError: false,
            generalError: false
        }
    })
    const [showSideMenu, setShowSideMenu] = useState(false);
    const abortControllerSignalRef = useRef(null);
    const SELECTED_CONTENT_OVERVIEW = {
        currentSectionId: 0,
        currentLessonId: 0,
        currentLessonTypeName: 'overview',
        currentLessonVersionId: null
    };
    const styles = {
        classroomContainer:{
            backgroundColor: theme.palette.surface.light[2]
        },
        sideContainer:{
        },
        contentContainer:{
        },
        toogleButton:{
            backgroundColor: theme.palette.background.paper,
            border: `solid 1px ${theme.palette.grey[100]}`,
        },
        bottomSheet:{
            backgroundColor: theme.palette.grey[400],
        },
        toogleWrapper:{
            backgroundColor: theme.palette.background.default,
        }
        // arrowIcon:{
        //     height: '30px',
        //     color: theme.palette.primary.contrastText
        // }
    }

    const requestClassroom = async (userId, contentProductId, signal) => {
        // TODO: Include product edition check: isAvailable
        const userConfig = await requestUserConfig(userId, contentProductId, signal);
        const contentSummary = await requestContentProductSummary(contentProductId, userConfig.localeId, 0, signal);
        if(isNotEmptyObject(contentSummary) && contentSummary.isAvailable){
            const system = {
                deviceName: userConfig.selectedDeviceName,
                softwareName: userConfig.selectedSoftwareName
            };
            if(!signal.aborted){
                setIsOpenFirstConfigModal(system.deviceName == null && system.softwareName == null)
                setClassroomState(classroomState => ({...classroomState, details:{...classroomState.details, isContentAvailable:true}}));
                requestContentProductStructure(user.basicData.id, contentProductId,  userConfig.localeId, system, 0, signal);
                requestUserProgress(user.basicData.id, contentProductId, system, signal);
            }
        }else{
            // This contentProductId cannot be displayed
            if(!signal.aborted){
                setClassroomState(classroomState => ({...classroomState, state:'error', details:{...classroomState.details, isContentAvailable:false, isError:true}, errorDetails:{...classroomState.errorDetails, noAvail:true}})); 
            }
        }
    }

    const requestUserConfig = async (userId, contentProductId, signal) => {
        try{
            setRequestingDataStatus(requestingDataStatus => ({...requestingDataStatus, details:{...requestingDataStatus.details, userConfig:true}}));
            const res = await getUserContentConfig(userId, contentProductId, 0, signal);
            const userConfig = res.data;
            if(isEmptyObject(userConfig)){
                // user is a guest so locale is not set and is assigned the client localeId 
                userConfig.localeId = localeCtx.localeId
            }
            if(!signal.aborted){
                setUserConfig(userConfig);
            }
            return userConfig;
        }catch(error){
            // wrong credentials is caught on the interceptor
            throw error;
        }finally{
            if(!signal.aborted){
                setRequestingDataStatus(requestingDataStatus => ({...requestingDataStatus, details:{...requestingDataStatus.details, userConfig:false}}));
            }
        }
    }

    const requestContentProductSummary = async (contentProductId, localeId, minimumTimeOut=0, signal) => {
        try{
            setRequestingDataStatus(requestingDataStatus => ({...requestingDataStatus, details:{...requestingDataStatus.details, contentSummary:true}}));
            const res = await getProductSummary(contentProductId, localeId, minimumTimeOut, signal);
            const data = res.data;
            if(!signal.aborted){
                if(!isEmptyObject(data.product)){
                    setContentSummary(data.product);
                }else{
                    setRequestingDataStatus(requestingDataStatus => ({...requestingDataStatus, errorDetails:{...requestingDataStatus.errorDetails, contentSummary:true}}));
                }
            }
            return data.product;
        }catch(error){
            // wrong product is caught on the interceptor
            // TODO: Process relevant error except CustomError sr_404
            const customError = getCustomErrorOrUndefined(error);
            if(customError && customError.cause === 'sr_404'){
                // Product not found cannot show page
                redirectApi.redirectToRoute('/404', {}, true)
            }else{
                return undefined
            }
        }finally{
            if(!signal.aborted){
                setRequestingDataStatus(requestingDataStatus => ({...requestingDataStatus, details:{...requestingDataStatus.details, contentSummary:false}}));
            }
        }
    }

    const requestContentProductStructure = async (userId, contentProductId, localeId, system, minimumTimeOut=0, signal) => {
        try{
            setRequestingDataStatus(requestingDataStatus => ({...requestingDataStatus, details:{...requestingDataStatus.details, contentStructure:true}}));
            const defaultContentSystem = !system.deviceName && !system.softwareName;
            const query = { defaultContentSystem };
            const res = await getContentProductStructure(userId, contentProductId, localeId, system, query, minimumTimeOut, signal)
            const data = res.data;
            if(!signal.aborted){
                setUserAccessDetails(data.userAccessDetails);
                if(isNotEmptyObject(data.contentStructure)){
                    setRetrievalDetails(data.retrievalDetails);
                    setContentStructure(data.contentStructure);
                }else{
                    // Content structure could not be retrieved
                    setRequestingDataStatus(requestingDataStatus => ({...requestingDataStatus, errorDetails:{...requestingDataStatus.errorDetails, contentStructure:true}}));
                }
            }
        }catch(error){
            // wrong credentials or wrong content product are caught on the interceptor
        }finally{
            if(!signal.aborted){
                setRequestingDataStatus(requestingDataStatus => ({...requestingDataStatus, details:{...requestingDataStatus.details, contentStructure:false}}));
            }
        }
    }

    const requestUserProgress = async (userId, contentProductId, system, signal) => {
        try{
            setRequestingDataStatus(requestingDataStatus => ({...requestingDataStatus, details:{...requestingDataStatus.details, userProgress:true}}));
            const res = await getUserContentProgress(userId, contentProductId, { system }, signal);
            const {
                userContentProductProgress,
                retrievalDetails   
            }= res.data;
            if(!signal.aborted){
                if(!isEmptyObject(userContentProductProgress)){
                    setUserProgress(userContentProductProgress);
                    const currentState = userContentProductProgress.currentState;
                    setContentSelectedAndType(currentState.currentSectionId, currentState.currentLessonId, currentState.currentLessonTypeName, currentState.currentLessonVersionId)
                }else{
                    // user progress could not be retrieved
                    setRequestingDataStatus(requestingDataStatus => ({...requestingDataStatus, errorDetails:{...requestingDataStatus.errorDetails, userProgress:true}}));
                }
            }
        }catch(error){
            // wrong credentials or wrong content product are caught on the interceptor
        }finally{
            if(!signal.aborted){
                setRequestingDataStatus(requestingDataStatus => ({...requestingDataStatus, details:{...requestingDataStatus.details, userProgress:false}}));
            }
        }
    }

    const setContentSelectedAndType = (currentSectionId, currentLessonId, currentLessonTypeName=null, currentLessonVersionId=null) => {
        let contentSelectedAndType;
        if(contentSelected == null || contentSelected.currentLessonId != currentLessonId){
            if(currentSectionId > 0 && currentLessonId > 0){
                if(currentLessonTypeName == null && currentLessonVersionId == null){
                    const lessonVersionId = contentStructure.lessonVersionMap[currentLessonId];
                    const lessonSummary = contentStructure.lessonSummaryMap[currentLessonId][lessonVersionId];
                    currentLessonTypeName = lessonSummary.lessonTypeName;
                    currentLessonVersionId = lessonSummary.description.lessonVersionId;
                }
                contentSelectedAndType = {
                    currentSectionId,
                    currentLessonId,
                    currentLessonTypeName,
                    currentLessonVersionId,
                }
            }else{
                contentSelectedAndType = SELECTED_CONTENT_OVERVIEW
            }
            setContentSelected(contentSelectedAndType);
        }
    }

    const updateUserConfig = async ( updatedValues, requestContent=false, requestUpdatedConfig=false, signal=abortControllerSignalRef.current, userId=user.basicData.id ) => {
        try{
            if(userConfig && userAccessDetails.hasAccess){
                const backUpPromise = backUpUserConfig(userId, contentProductId, updatedValues, 0);
                const updatingSelectedSystem = updatedValues.hasOwnProperty('selectedDeviceName') || updatedValues.hasOwnProperty('selectedSoftwareName');
                // let resyncPromise;
                if(updatingSelectedSystem){
                    await backUpPromise;
                //     system = {
                //         deviceName: updatedValues.selectedDeviceName,
                //         softwareName: updatedValues.selectedSoftwareName,
                //     }
                //     await resyncUserProgressWithSystem(userId, contentProductId, system, 0);
                }
                if(requestUpdatedConfig){
                    await backUpPromise;
                    await requestUserConfig(userId, contentProductId, signal);
                }else{
                    if(!signal.aborted){
                        setUserConfig(userConfig => ({...userConfig, ...updatedValues, details:{...userConfig.details, ...updatedValues.details}}));
                    }
                }
                if(requestContent){
                    const localeId = updatedValues.localeId || userConfig.localeId;
                    await backUpPromise;
                    let system;
                    if(updatingSelectedSystem){
                        // await resyncPromise;
                        system = {
                            deviceName: updatedValues.selectedDeviceName,
                            softwareName: updatedValues.selectedSoftwareName,
                        }
                        await requestUserProgress(userId, contentProductId, system, signal);
                    }else{
                        system = {
                            deviceName: userConfig.selectedDeviceName,
                            softwareName: userConfig.selectedSoftwareName,
                        }
                    }
                    await requestContentProductSummary(contentProductId, localeId, 0, signal);
                    await requestContentProductStructure(userId, contentProductId, localeId, system, 0, signal);
                }
            }
        }catch(error){
            // BackUp and Request exceptions are handled in each function
            // Except backUpUserConfig and resyncUserProgressWithSystem
            throw error
        }
    };

    const updateUserProgress = ( elementType, updatedValues, contentSelected={}, signal=abortControllerSignalRef.current, userId=user.basicData.id ) =>{
        try{
            if(userProgress && userAccessDetails.hasAccess){
                let updatedLesson;
                let updatedSection;
                let updatedSectionProgress;
                const updatedValuesMap = {};
                switch(elementType){
                    case 'lesson':
                        updatedValuesMap['lesson'] = {...updatedValues};
                        delete updatedValues['markAsCompleted'];
                    case 'section':
                        updatedLesson = contentStructure.lessonSummaryMap[contentSelected.lessonId][contentSelected.lessonVersionId];
                        updatedSection = contentStructure.sectionSummaryMap[contentSelected.sectionId];
                        updatedSectionProgress = userProgress.sectionProgressMap[contentSelected.sectionId];
                        if(elementType != 'section' && updatedValues.hasOwnProperty('completionDate')){
                            updatedValues['totalCompletedMin'] = Math.min(updatedSection.lengthInMin, updatedSectionProgress.totalCompletedMin + updatedLesson.lengthInMin);
                            if(updatedValues['totalCompletedMin'] >= updatedSection.lengthInMin){
                                // Check if all lessons are completed
                            }else{
                                delete updatedValues['completionDate'];
                            }
                        }
                        updatedValuesMap['section'] = {...updatedValues};
                    case 'contentProduct':
                        let currentUserPosition = {};
                        if(elementType != 'contentProduct'){
                            if(updatedValues.hasOwnProperty('completionDate')){
                                updatedValues['totalCompletedMin'] = Math.min(contentSummary.summaryDetails.lengthInMin, userProgress.totalCompletedMin + updatedLesson.lengthInMin);
                                if(updatedValues['totalCompletedMin'] >= contentSummary.summaryDetails.lengthInMin){
                                    // Check if all sections are completed
                                }else{
                                    delete updatedValues['completionDate'];
                                }
                            }
                            currentUserPosition = {
                                currentLessonId: updatedLesson.id,
                                currentSectionId: updatedSection.id,
                                currentLessonVersionId: updatedLesson.description.lessonVersionId,
                                currentLessonTypeName: updatedLesson.lessonTypeName,
                            }
                        }else{
                            // just updating content product progress
                        }    
                        updatedValuesMap['contentProduct'] = {
                            ... updatedValues,
                            ... currentUserPosition,
                        } 
                        break;
                };
                // Normalized date format in updated params on the client
                Object.entries(updatedValuesMap).forEach(([type, values]) => {
                    Object.entries(values).forEach(([key, updatedValue]) => {
                        if(isValidDate(updatedValue)){
                            updatedValuesMap[type][key] =  getInsertableDate(updatedValue); //updatedValue.toJSON();
                        }
                    })
                })
                backUpUserProgress(userId, contentProductId, contentSelected, updatedValuesMap);
                if(!signal.aborted){
                    setUserProgress(userProgress => {
                        if(updatedValuesMap.hasOwnProperty('lesson')){
                            userProgress['lessonProgressMap'][contentSelected.lessonId][contentSelected.lessonVersionId] = {...userProgress['lessonProgressMap'][contentSelected.lessonId][contentSelected.lessonVersionId], ...updatedValuesMap.lesson}
                        }
                        if(updatedValuesMap.hasOwnProperty('section')){
                            userProgress['sectionProgressMap'][contentSelected.sectionId] = {...userProgress['sectionProgressMap'][contentSelected.sectionId], ...updatedValuesMap.section}
                        }
                        return {...userProgress, ...updatedValuesMap['contentProduct']};
                    });
                }
                // console.log(elementType, contentSelected, updatedValues, userId);
            }
        }catch(error){
            // TODO: Only synchronous errors. 
            // BackUp and request exceptions are handled in each function
            console.log(error)
        }
    };

    const onUpdateUserConfigByLocaleContextChange = async (localeId, requestContent=true, requestUpdatedConfig=true, signal=null) => {
        try{
            await updateUserConfig({localeId}, requestContent, requestUpdatedConfig, signal)
        }catch(error){
            addMessages([{
                message: t("yourCurrentContentConfigCannotBeUpdatedToThisLanguage"),
                severity: 'warning'
            }])
        }
    }

    const onFetchUserProgress = (elementType, elementId, data, signal=abortControllerSignalRef.current) => {
        if(!signal.aborted){
            const updatedMap = `${elementType}ProgressMap`;
            setUserProgress(userProgress => ({...userProgress, [updatedMap]:{...userProgress[updatedMap], [elementId]: {...data}}}))
        }
    }

    /**
     * Return the next element defined on the contentStructure
     * @param {Object} contentStructure 
     * @param {Object} contentSelected 
     * @return {Object} {sectionId: null|int, lessonId: null|int}
     */
    const nextContentLesson = ( contentStructure, contentSelected ) => {
        const currentSectionId = contentSelected.currentSectionId;
        const currentLessonId = contentSelected.currentLessonId;
        const currentLessonVersionId = contentSelected.currentLessonVersionId;
        let nextSectionIndex = null;
        let nextLessonIndex = null;
        if (currentSectionId == null || currentSectionId == 0){
            // Set first element
            nextSectionIndex = 0;
            nextLessonIndex = 0;
        } else {
            // Calculate next element
            const currentSection = contentStructure.sectionSummaryMap[currentSectionId];
            const currentSectionIndex = currentSection.sectionOrderIndex;
            const currentSectionLastElementIndex = currentSection.lessonIdsStructure.length - 1
            const currentSectionLastElementId = currentSection.lessonIdsStructure[currentSectionLastElementIndex];
            if (currentLessonId === currentSectionLastElementId){
                // Calculate next Section
                const lastSectionIndex = contentStructure.sectionIdsStructure.length - 1
                const lastSectionId = contentStructure.sectionIdsStructure[lastSectionIndex];
                if(currentSectionId === lastSectionId){
                    // There is no more sections
                    // nextSection and nextElement is set to null
                } else {
                    // Start Next section
                    nextSectionIndex = currentSectionIndex + 1;
                    nextLessonIndex = 0;
                }
    
            } else {
                // Next element in the current section
                const currentLesson = contentStructure.lessonSummaryMap[currentLessonId][currentLessonVersionId];
                const currentLessonIndex = currentLesson.lessonOrderIndex;
                nextLessonIndex = currentLessonIndex + 1;
                nextSectionIndex = currentSectionIndex;
            }
        }
        const nextContentSelected = {};
        if(nextLessonIndex !== null && nextSectionIndex !== null){
            const nextSectionId = contentStructure.sectionIdsStructure[nextSectionIndex];
            const nextLessonId = contentStructure.sectionSummaryMap[nextSectionId].lessonIdsStructure[nextLessonIndex];
            nextContentSelected.sectionId = nextSectionId;
            nextContentSelected.lessonId = nextLessonId;
        }
        return nextContentSelected
    }

    /**
     * Return the previous element defined in the contentStructure
     * @param {*} contentStructure 
     * @param {*} contentSelected 
     * @return {Object} {sectionId: null|int, lessonId: null|int}
     */
    const prevContentLesson = ( contentStructure, contentSelected ) => {
        const currentSectionId = contentSelected.currentSectionId;
        const currentLessonId = contentSelected.currentLessonId;
        const currentLessonVersionId = contentSelected.currentLessonVersionId;
        let prevSectionIndex = null;
        let prevLessonIndex = null;
        if (currentSectionId == null || currentSectionId == 0){
            // There is no prev section and element
            // prevSectionIndex and prevElementIndex is set to null
        } else {
            // Calculate prev element
            const currentSection = contentStructure.sectionSummaryMap[currentSectionId];
            const currentSectionIndex = currentSection.sectionOrderIndex;
            if (currentLessonId === currentSection.lessonIdsStructure[0]){
                // Calculate prev Section
                if(currentSectionId === contentStructure.sectionIdsStructure[0]){
                    // There is no prev section and element
                    // prevSectionIndex and prevElementIndex is set to null
                } else {
                    // Last element Previous section
                    prevSectionIndex = currentSectionIndex - 1;
                    const prevSectionId = contentStructure.sectionIdsStructure[prevSectionIndex];
                    prevLessonIndex = contentStructure.sectionSummaryMap[prevSectionId].lessonIdsStructure.length - 1
                }
            } else {
                // Prev element in the current section
                const currentLesson = contentStructure.lessonSummaryMap[currentLessonId][currentLessonVersionId];
                const currentElementIndex = currentLesson.lessonOrderIndex;
                prevLessonIndex = currentElementIndex - 1;
                prevSectionIndex = currentSectionIndex;
            }
        }
        const prevContentSelected = {}
        if (prevLessonIndex !== null && prevSectionIndex !== null) {
            const prevSectionId = contentStructure.sectionIdsStructure[prevSectionIndex]; 
            const prevLessonId = contentStructure.sectionSummaryMap[prevSectionId].lessonIdsStructure[prevLessonIndex];
            prevContentSelected.sectionId = prevSectionId;
            prevContentSelected.lessonId = prevLessonId;
        }
        return prevContentSelected;
    }

    const contentNavMoves = {
        nextElement: () => {
            setContentSelectedAndType(contentNav.next.sectionId, contentNav.next.lessonId);
        },
        prevElement: () => {
            setContentSelectedAndType(contentNav.prev.sectionId, contentNav.prev.lessonId);
        }
    }

    const handleSideMenu = () => {
        setShowSideMenu(prev => !prev)
    }

    const isClassroomReady = () => {
        return contentSummary !== null && contentStructure !== null 
        && userProgress !== null && userAccessDetails !== null 
        && contentSelected !== null && contentNav !== null
        && userConfig !== null
    }

    useEffect(() => {
        if(isClassroomReady()){
            const abortController = new AbortController();
            onUpdateUserConfigByLocaleContextChange(localeCtx.localeId, true, true, abortController.signal)
            return(() => abortController.abort())
        }
    },[localeCtx.localeId])

    useEffect(() => {
        const userId = getFromSafeObject(user, 'basicData.id');
        if(userId && contentProductId != null){
            const abortController = new AbortController();
            abortControllerSignalRef.current = abortController.signal
            requestClassroom(user.basicData.id, contentProductId, abortController.signal);
            return(() => {
                abortController.abort();
            })
        }
    },[user, contentProductId])

    useEffect(() => {
        if (contentStructure && contentSelected){
            const prev = prevContentLesson(contentStructure, contentSelected)
            const next = nextContentLesson(contentStructure, contentSelected)
            setContentNav({ prev:prev, next:next})
            window.scrollTo(0,0);
        }
    }, [contentStructure, contentSelected])

    useEffect(() => {
        const isRequesting = Object.values(requestingDataStatus.details).reduce((accumulated, current) => {return accumulated || current}, false);
        const isClassroomReady = classroomState.details.isClassroomReady;
        const isError = classroomState.state === 'error'
        setClassroomState(classroomState => ({
            ...classroomState, 
            details:{
                ...classroomState.details, 
                isClassroomRequestingData:isRequesting
            }, 
            state: isRequesting && !isClassroomReady && !isError ? 'requestingData' : classroomState.state
        }));
    },[requestingDataStatus.details])

    useEffect(() => {
        const isRequestingError = Object.values(requestingDataStatus.errorDetails).reduce((accumulated, current) => {return accumulated || current}, false);
        setClassroomState(classroomState => ({
            ...classroomState,
            details:{
                ...classroomState.details, 
                isError:isRequestingError
            },
            errorDetails:{
                ...classroomState.errorDetails,
                requestingDataError:isRequestingError
            },
            state: isRequestingError ? 'error' : classroomState.state
        }));
    },[requestingDataStatus.errorDetails])

    useEffect(() => {
        if(isClassroomReady()){
            setClassroomState(classroomState => ({...classroomState, details:{ ...classroomState.details, isClassroomReady:true}, state:'ready'}));
        }
    },[contentSummary, contentStructure, userProgress, userConfig, userAccessDetails, contentSelected, contentNav])

    useEffect(() => {
        const systemDetails = retrievalDetails?.systemDetails
        if(isNotEmptyObject(systemDetails) && systemDetails.retrievedType === 'requested' && !sameSystems(systemDetails.system, systemDetails.versionedSystem)){
            // const versionedSystemName = t('common:systemRetrieval.systemDeviceNameWithSoftwareName', {softwareName:systemDetails.versionedSystem.softwareName.toLocaleUpperCase(), deviceName:systemDetails.versionedSystem.deviceName.toLocaleUpperCase()})
            // const requestedSystemName = t('common:systemRetrieval.systemDeviceNameWithSoftwareName', {softwareName:systemDetails.system.softwareName.toLocaleUpperCase(), deviceName:systemDetails.system.deviceName.toLocaleUpperCase()});

            const timeoutId = setTimeout(() => {
                setShowRetrievalDetails(true);
            },500)
            return(() => clearTimeout(timeoutId))
        }
    },[retrievalDetails])

    useEffect(() => {
        if(showRetrievalDetails){
            const timeoutId = setTimeout(() => setShowRetrievalDetails(false), 5000);
            return(() => clearTimeout(timeoutId))
        }
    },[showRetrievalDetails])

    const getComponentToRender = () => {
        let ComponentToRender;
        switch(classroomState.state){
            case 'mounting':
            case 'requestingData':
                ComponentToRender = 
                <div className='classroom-error-loading-container'>
                    <LoadingComponent visibleElements='circle' />
                </div>
                break;
            case 'ready':
                if(isClassroomReady()){
                    ComponentToRender = 
                    <>
                    <div className={`classroom-main-container`}>
                        {isOpenFirstConfigModal === true &&
                            <PopUpClassContentFirstUserConfig userConfig={userConfig} contentSummary={contentSummary} onUpdate={updateUserConfig} showModal={isOpenFirstConfigModal} onClickClose={() => setIsOpenFirstConfigModal(false)} isDialog={true} />
                        }
                        <div className='classroom-main-column-wrapper' style={styles.contentContainer}>
                            <ClassroomMainColumn 
                                userConfig={userConfig} 
                                userProgress={userProgress} 
                                userAccessDetails={userAccessDetails} 
                                contentSummary={contentSummary} 
                                contentStructure={contentStructure} 
                                contentSelected={contentSelected}
                                contentNav={contentNav} 
                                contentNavMoves={contentNavMoves} 
                                onUpdateUserConfig={updateUserConfig} 
                                onUpdateUserProgress={updateUserProgress} 
                                onFetchUserProgress={onFetchUserProgress}
                            />
                            {matchDesktop ?
                                    <BigFooterNoLinks user={user} />
                                    // <BigFooter />
                                :
                                null
                            }
                        </div>
                        {matchDesktop ?
                            <div className='classroom-side-column-wrapper'  style={styles.sideContainer}>
                                <ClassroomSideColumn 
                                    userAccessDetails={userAccessDetails} 
                                    userProgress={userProgress} 
                                    userConfig={userConfig} 
                                    contentStructure={contentStructure} 
                                    contentSelected={contentSelected} 
                                    setContentSelected={setContentSelectedAndType} 
                                />
                            </div>    
                            :
                            <div className={`classroom-side-column-wrapper${showSideMenu ? ' show-side-bar' : ' hide-side-bar'}`}  style={styles.sideContainer}>
                                <div style={{position:'relative', height:'100%', width:'100%'}}> 
                                    <div style={{position:'sticky', top:'60px', alignItems:'end', height:'calc(100vh - 60px)', width: '100%', display:'flex', alignItems:'flex-end'}}>
                                        <div style={{width:'100%'}}>
                                            <div className={`classroom-side-column-toggle-button${showSideMenu ? ' show-side-bar' : ' hide-side-bar'}`} onClick={handleSideMenu} style={styles.toogleButton}>
                                                <div className='classroom-side-bottom-sheet' style={styles.bottomSheet}/>
                                            </div>
                                            <div className={`classroom-side-column-toggle-wrapper${showSideMenu ? ' show-side-bar' : ' hide-side-bar'}`} style={styles.toogleWrapper}>
                                                <ClassroomSideColumn 
                                                    userAccessDetails={userAccessDetails} 
                                                    userProgress={userProgress} 
                                                    userConfig={userConfig} 
                                                    contentStructure={contentStructure} 
                                                    contentSelected={contentSelected} 
                                                    setContentSelected={setContentSelectedAndType} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={`classroom-system-version-display-alert-container${showRetrievalDetails ? ' shown' : ''}`}>
                        <Alert severity='info'>
                            <StyledTypography variant="body2">
                                {
                                    t('common:systemRetrieval.adaptedSystemRetrieved', { 
                                        versionedSystemName: t('common:systemRetrieval.systemDeviceNameWithSoftwareName', {softwareName: getFromSafeObject(retrievalDetails,'systemDetails.versionedSystem.softwareName','').toLocaleUpperCase(), deviceName:getFromSafeObject(retrievalDetails,'systemDetails.versionedSystem.deviceName','').toLocaleUpperCase()}), 
                                        requestedSystemName: t('common:systemRetrieval.systemDeviceNameWithSoftwareName', {softwareName:getFromSafeObject(retrievalDetails, 'systemDetails.system.softwareName','').toLocaleUpperCase(), deviceName:getFromSafeObject(retrievalDetails,'systemDetails.system.deviceName','').toLocaleUpperCase()})
                                    })
                                }
                            </StyledTypography>
                        </Alert>
                    </div>
                    </>
                }
                break;
            case 'error':
            default:
                ComponentToRender = 
                <div className='classroom-error-loading-container'>
                    <ResourcePageError errorDetails={classroomState.errorDetails} resourceType={'content'} redirectDetails={{ path:ERROR_PATH_TO_REDIRECT, label:t('common:goToMyContent') }}/>
                </div>
        }
        return ComponentToRender;
    }

    return (
        <>
            {showSideMenu && !matchDesktop ? 
                <div className='classroom-bottom-sheet-background'/>
                :
                    null
            }
            <div className='classroom-wrapper' style={styles.classroomContainer}>
                {getComponentToRender()}
                {matchDesktop ?
                    // Rendered inside mainColumnWrapper 
                    null
                    :
                    <BigFooterNoLinks user={user} />
                    // <BigFooter />
                }
            </div>
        </>
    )
}

export default Classroom
