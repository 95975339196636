import { useState, useEffect } from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import ImgLazyLoad from '../ImgLazyLoad/ImgLazyLoad';
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
import StyledHTMLString from '../StyledHTMLString/StyledHTMLString';
import './contentDescription.css';

const ContentDescription = ({ contentPage, imgURI, wrapperStyle, noVerticalPadding=false, imgLazyLoadThreshold='600px' }) => {
    const IMG_MAX_WIDTH_PX = [500, 340]; // [!mobile, mobile]
    const assetProvider = new ImgDN(cloudinary);
    const matchDesktop = useMediaQuery('(min-width: 1120px)');
    const matchMobile = useMediaQuery('(max-width: 680px)');
    const [imgDescriptionURL, setImgDescriptionURL] = useState(null);
    const styles = {
        wrapper: wrapperStyle ? 
            wrapperStyle :
            {
                backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0,0,0,0.2))',
                backgroundColor: contentPage.palette.primary.main,
        },
        title: {
            color: contentPage.palette.secondary.main,
            fontWeight: 'bold'
        },
        description: {
            color: contentPage.palette.primary.contrastText,
            whiteSpace: 'pre-line'
        }
    }
    useEffect(() => {
        if(imgURI){
            const imageTransformation = {width: matchMobile ? IMG_MAX_WIDTH_PX[1] : IMG_MAX_WIDTH_PX[0]}
            const imgURL = assetProvider.getAssetUrl(imgURI, imageTransformation);
            setImgDescriptionURL(imgURL);
        }else{
            setImgDescriptionURL(null)
        }
    },[imgURI, matchMobile])
    return (
        <div className='content-description-wrapper' style={styles.wrapper}>
            {matchDesktop ? 
                <div className={`content-description-main-container${noVerticalPadding ? ' no-vertical-padding' : ''}`}>
                    <div className='content-description-description-container'>
                        <Typography variant={matchMobile ? 'h6':'h5'} style={styles.title}>
                            {contentPage.contentDescription.title}
                        </Typography>
                        <StyledHTMLString typographyProps={{variant:'body1', style:styles.description}}>
                            {contentPage.contentDescription.description}
                        </StyledHTMLString>
                    </div>
                    {imgURI ?
                        <div className='content-description-img-container'>
                            <ImgLazyLoad 
                                className='content-description-img' 
                                alt={'content-product cover img'}
                                src={imgDescriptionURL}
                                threshold={imgLazyLoadThreshold}
                            />
                        </div>
                        :
                        null
                    }
                </div>
                :
                <div className={`content-description-main-container${noVerticalPadding ? ' no-vertical-padding' : ''}`}>
                    <div className='content-description-title-container'>
                        <Typography variant={matchMobile ? 'h6':'h5'} style={styles.title}>
                            {contentPage.contentDescription.title}
                        </Typography>
                    </div>
                    <div className='content-description-description-img-container'>
                        <div className='content-description-description-container'>
                            <Typography variant='body1' style={styles.description}>
                                {contentPage.contentDescription.description}
                            </Typography>
                        </div>
                        {imgURI ? 
                            <div className='content-description-img-container'>
                                <ImgLazyLoad 
                                    className='content-description-img' 
                                    alt={'content-product cover img'}
                                    src={imgDescriptionURL}
                                    threshold={imgLazyLoadThreshold}
                                />
                            </div>
                            : 
                            null
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default ContentDescription