import { useTheme } from '@material-ui/styles';
import QuexEdLogo from '../../img/quex-ed-logo.svg';
import QuexSLogo from '../../img/quex-s-logo.svg';
import EductorLogo from '../../img/eductor-logo.svg';
import ScioLogo from '../../img/scio-logo.svg';
import IndigoLogo from '../../img/indigo-logo.svg';
import NucleusLogo from '../../img/nucleus-logo.svg';
import EDXLogo from '../../img/edx-logo.svg';
import Q9Logo from '../../img/q9-logo.svg';
import './deviceLogo.css'

const DeviceLogo = ({ deviceName, svgStyle={} }) => {
    const theme = useTheme();
    const iconStyle = {
        display: 'block',
        fill: theme.palette.grey[400],
    }
    let DeviceIcon;
    switch(deviceName){
        case 'eductor':
            DeviceIcon = EductorLogo;
            break;
        case 'scio':
            DeviceIcon = ScioLogo;
            break;
        case 'quex-ed':
            DeviceIcon = QuexEdLogo;
            break;
        case 'quex-s':
            DeviceIcon = QuexSLogo;
            break;
        case 'indigo':
            DeviceIcon = IndigoLogo;
            break;
        case 'nucleus':
            DeviceIcon = NucleusLogo;
            break;
        case 'ed-x':
            DeviceIcon = EDXLogo;
            break;
        case 'q9':
            DeviceIcon = Q9Logo;
            break;
    }
    return (
        DeviceIcon ?
            <DeviceIcon style={{ ...iconStyle, ...svgStyle }} />
            :
            null
    )
}

export default DeviceLogo