import { useTranslation } from 'react-i18next'
import { Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import WorkguideBetterThanAProtocol from '../WorkguideBetterThanAProtocol/WorkguideBetterThanAProtocol'
import WorkguideFeatures from '../WorkguideFeatures/WorkguideFeatures'
import './contentWorkguideVsProtocol.css'

const ContentWorkguideVsProtocol = ({ noVerticalPadding=false, widgetStyleMap={}, includeTitle=false }) => {
    const { t } = useTranslation('contentProduct', { keyPrefix: 'contentWorkguideVsProtocol'});
    const matchMobile = useMediaQuery('(max-width:680px)');
    const theme = useTheme()
    return (
        <div className={`content-workguide-vs-protocol-main-container${noVerticalPadding ? ' no-vertical-padding' : ''}`}>
            <WorkguideFeatures noVerticalPadding={true} subtitle={t('subtitle')} {...includeTitle ? {title:t('title')} : {}}/>
            <WorkguideBetterThanAProtocol noVerticalPadding={true} contentWrapperStyle={{
                backgroundColor: theme.palette.surface.light[1],
                borderRadius: '5px',
                padding: matchMobile ? '24px' : '32px',
                ...widgetStyleMap
            }}/>
        </div>
    )
}

export default ContentWorkguideVsProtocol