/*
    This module contains a set of functions to handle the API queries related to form population
*/
import { arrayToQueryParam } from "./aux";
import { getInternationalizedLocaleFileNameOrNull } from "./internationalizationHelpers";
import { publicRequest, publicThirdPartyRequest } from "./requestMethods";

const getCountriesFn = async ( params ) => {
    const {
        localeId,
    } = params;
    const res = await publicRequest.get(`/static-content/get-countries/${localeId}`)
    return res;
}

const getLocalesFn = async (params, signal=null) => {
    const {
        localeId,
        expand
    } = params;
    return publicRequest.get(`/static-content/get-locales`, { params, signal })
}

const getDefaultLocalesFn = async (params, signal=null) => {
    return publicRequest.get(`/static-content/get-default-locale`, { signal })
}

const getAvailCurrencyFn = async ( params, signal=null ) => {
    const {
        countryCode,
    } = params;
    return publicRequest.get(`/static-content/get-avail-currency`, { params: {countryCode}, signal})
}

const getAvailCountryFn = async ( params, signal=null )  => {
    const {
        countryCode,
        localeId
    } = params;
    return publicRequest.get(`/static-content/get-avail-country/locale/${localeId}`, { params: { countryCode }, signal});
}

const getAvailStateFn = async ( params, signal=null )  => {
    const {
        countryCode,
        stateCode
    } = params;
    return publicRequest.get(`/static-content/get-avail-state`, { params: { countryCode, stateCode }, signal});
}

const getStatesFn = async ( params ) => {
    const {
        countryCode
    } = params;
    const res = await publicRequest.get(`/static-content/get-states/${countryCode}`);
    return res;
}

const getProfessionsFn = async ( params, signal=null ) => {
    const {
        localeId
    } = params;
    return publicRequest.get(`/static-content/get-professions`, { params: {localeId}, signal});
}

const getCompatibleSystemsFn = async ( params, signal=null ) => {
    const {
        by
    } = params;
    const res = await publicRequest.get(`/static-content/get-compatible-systems/${by}`, {signal: signal});
    return res;
}

const getHandSelectedProductFn = async ( params, signal=null ) => {
    const {
        productTypeName,
        selectedProductTypeArray=[],
        localeId,
        filteredBy,
    } = params;
    return publicRequest.get(`/static-content/hand-selected-products/${localeId}`, { params: { type:productTypeName, selectedProductTypeArray:arrayToQueryParam(selectedProductTypeArray), filteredBy: arrayToQueryParam(filteredBy) } , signal: signal});
}

const getDisplayablePromoCodeFn = async ( params, signal=null) => {
    const {
        localeId,
        date
    } = params;
    return publicRequest.get(`/static-content/displayable-promo-code/${localeId}`, { params: {date: date}, signal });
}

const getReadableFile = async (url, signal=null) => {
    if(url){
        return publicThirdPartyRequest.get(url, { signal });
    }else{
        return Promise.reject('null or undefined url')
    }
}

const fetchPublicFileFromApiFn = async(params, signal=null) => {
    const {
        folderType,
        fileUri
    } = params;
    return publicRequest.get(`/static-content/file/${folderType}/${fileUri}`, { signal });
}

const getFAQ = async (languageId, signal=null) => {
    const FILENAME = 'faq.json'
    const JSON_FILES_PUBLIC_FOLDER_PATH = '/json';
    const internationalFilename = getInternationalizedLocaleFileNameOrNull(languageId, FILENAME);
    const url= internationalFilename ? [JSON_FILES_PUBLIC_FOLDER_PATH, languageId, internationalFilename].join('/') : null;
    return getReadableFile(url, signal)
}

export{
    getCountriesFn,
    getStatesFn,
    getProfessionsFn,
    getCompatibleSystemsFn,
    getAvailCurrencyFn,
    getAvailCountryFn,
    getAvailStateFn,
    getLocalesFn,
    getDefaultLocalesFn,
    getHandSelectedProductFn,
    getDisplayablePromoCodeFn,
    getReadableFile,
    fetchPublicFileFromApiFn,
    getFAQ
}