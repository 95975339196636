import { createContext, useEffect, useRef, useState } from "react"
import { createUserFavoriteProductArray, getUserFavoriteProductArray, removeUserFavoriteProductArray } from "../services/userServices";
import { getFromSafeObject, isNotEmptyObject } from "../aux/aux";
import PopUpLogIn from "../components/PopUpLogIn/PopUpLogIn";
import PopUpSimpleFeedback from "../components/PopUpSimpleFeedback/PopUpSimpleFeedback";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const UserFavoriteProductContext = createContext(null);
const UpdateUserFavoriteProductContext = createContext(null);

const UserFavoriteProductProvider = ({ user, setUser, children }) => {
    const { t } = useTranslation('common', { keyPrefix:'userFavoriteProductProvider'})
    const [userFavoriteProductMap, _setUserFavoriteProductMap] = useState(null);
    const [showLogInSignInPopUp, setShowLogInSignInPopUp] = useState(false);
    const [errorPopUp, setErrorPopUp] = useState({show:false, msg:undefined, type:'error'});
    const userFavoriteProductMapRef = useRef(null);
    const setUserFavoriteProduct = async (productIdentifier, isFavorite, createdAt=new Date(), verboseError=true) => {
        const userId = getFromSafeObject(user, 'basicData.id')
        if(userId){
            try{
                if(userFavoriteProductMapRef.current != null){
                    const updateUserFavoriteProductBackend = isFavorite ? createUserFavoriteProductArray : removeUserFavoriteProductArray
                    productIdentifier = { ...productIdentifier, createdAt}
                    await updateUserFavoriteProductBackend(userId, [productIdentifier], 0);
                    _setUserFavoriteProductMap(prev => ({ ...prev, [productIdentifier.productId]:isFavorite ? productIdentifier : null }));
                }else{
                    throw new Error()
                }
            }catch(error){
                const msg = `${t('cannotSetYourFavoriteNow')}. ${t('tryItLater')}`;
                setErrorPopUp(prev => ({ ...prev, show:true, msg }))
            }
        }else{
            setShowLogInSignInPopUp(true);
        }
    }
    const requestUserFavoriteProductArray = async(productIdentifierArray, signal=null) => {
        try{
            const userId = getFromSafeObject(user, 'basicData.id');
            if(userId){
                if(productIdentifierArray.length > 0){
                    const res = await getUserFavoriteProductArray(userId, productIdentifierArray, 0, signal);
                    const {
                        userFavoriteProductArray
                    } = res.data
                    if(!signal.aborted){
                        _setUserFavoriteProductMap(prev => {
                            const newMap = userFavoriteProductArray.reduce((map, userFavoriteProduct) => ({ ...map, [userFavoriteProduct.productId]:userFavoriteProduct }), {});
                            return ({...prev, ...newMap });
                        })
                    }
                }
            }
        }catch(error){
            // TODO: implement
        }
    }

    const isUserFavoriteProduct = (productIdentifier) => {
        return isNotEmptyObject(userFavoriteProductMap) && isNotEmptyObject(productIdentifier) ? userFavoriteProductMap[productIdentifier.productId] : false;
    }

    useEffect(() => {
        if(!user){
            _setUserFavoriteProductMap(null);
        }
    },[user])

    useEffect(() => {
        userFavoriteProductMapRef.current = userFavoriteProductMap
    },[userFavoriteProductMap])

    return(
        <UserFavoriteProductContext.Provider value={{isUserFavoriteProduct}}>
            <UpdateUserFavoriteProductContext.Provider value={{requestUserFavoriteProductArray, setUserFavoriteProduct}}>
                <>
                    <PopUpLogIn setUser={setUser} setShowModal={setShowLogInSignInPopUp} showModal={showLogInSignInPopUp} passiveVerification={process.env.REACT_APP_TARGET_ENV === 'staging'}/>
                    <PopUpSimpleFeedback show={errorPopUp.show} animationType={errorPopUp.type} onClickClose={() => setErrorPopUp(prev => ({...prev, show:false}))} >
                        <Typography variant="body1">
                            {errorPopUp.msg}
                        </Typography>
                    </PopUpSimpleFeedback>
                    { children }
                </>
            </UpdateUserFavoriteProductContext.Provider>
        </UserFavoriteProductContext.Provider>
    )
}

export { UserFavoriteProductProvider, UserFavoriteProductContext, UpdateUserFavoriteProductContext }