import ReactDOM from 'react-dom';
import {useState, useEffect, useRef} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useDetectSticky } from '../../customHooks/useDetectSticky';
import { Typography, useMediaQuery, useScrollTrigger } from '@material-ui/core';

//Components
import ContentHero from '../../components/ContentHero/ContentHero';
import ContentFeatures from '../../components/ContentFeatures/ContentFeatures';
import ContentPreviewAndDescription from '../../components/ContentPreviewAndDescription/ContentPreviewAndDescription';
import ContentBasicFeatures from '../../components/ContentBasicFeatures/ContentBasicFeatures';
import ContentSocialComments from '../../components/ContentSocialComments/ContentSocialComments';
import ContentPurchasePerks from '../../components/ContentPurchasePerks/ContentPurchasePerks';
import ContentConsumptionOptions from '../../components/ContentConsumptionOptions/ContentConsumptionOptions';
import ContentFAQ from '../../components/ContentFAQ/ContentFAQ';
import ContactSupportSection from '../../components/ContactSupportSection/ContactSupportSection';
import PurchaseProcessModal from '../../components/PurchaseProcessModal/PurchaseProcessModal';
import RecommendedContentSection from '../../components/RecommendedContentSection/RecommendedContentSection';
import MailingListSubscriptionSection from '../../components/MailingListSubscriptionSection/MailingListSubscriptionSection';
import BannerActionReminder from '../../components/BannerActionReminder/BannerActionReminder';
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks';

import SingleContentItinerary from '../../components/SingleContentItinerary/SingleContentItinerary';
import ContentProfessors from '../../components/ContentProfessors/ContentProfessors';
import NestSections from '../../components/NestSections/NestSections';
import { productAmountHasDiscountCouponApplied } from '../../aux/purchaseHelpers';
import { getFromSafeObject, getInsertableDate, getSafeDateOrUndefined, isEmptyObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import BannerPromo from '../../components/BannerPromo/BannerPromo';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import { getContentProductStructurePreview, getProductConsumptionDetailsCollection, getProductConsumptionOptionMap, getProductPage } from '../../services/visitorServices';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { getFilteredTempConsumptionOptionCategoryMapToUserSubscription, getProductConsumptionStatusDetails, isProductSubscriptableBySubscriptionLevel } from '../../aux/productHelpers';
import { getUserContentProgress } from '../../services/contentServices';
import { isPromoCodeDisplayableInProductPage } from '../../aux/displayablePromoCodeHelpers';
// Styles
import { addProductToUserSubscription, getUserAccessToProductDetailsCollection } from '../../services/userServices';
import { useMemo } from 'react';
import PopUpContactSupport from '../../components/PopUpContactSupport /PopUpContactSupport';
import { useCountryAndCurrencyCtx } from '../../customHooks/useCountryAndCurrencyCtx';
import { useDisplayablePromoCodeCtx } from '../../customHooks/useDisplayablePromoCodeCtx';
import { getCustomErrorOrUndefined, isCustomError } from '../../aux/errorHelpers';
import { useRedirect } from '../../customHooks/useRedirect';
import AddToSubscriptionModal from '../../components/AddToSubscriptionModal/AddToSubscriptionModal';
import ResourcePageError from '../../components/PageError/ResourcePageError';
import { useUpdateUserProductNotificationCtx } from  '../../customHooks/useUpdateUserProductNotificationCtx';
import { useUserProductNotificationCtx } from  '../../customHooks/useUserProductNotificationCtx';
import "./content.css";
import ContentPreviewDescriptionFeaturesAndMultiSystem from '../../components/ContentPreviewDescriptionFeaturesAndMultiSystem/ContentPreviewDescriptionFeaturesAndMultiSystem';
import ContentWorkguideAndMultiSystem from '../../components/ContentWorkguideAndMultiSystem/ContentWorkguideAndMultiSystem';
import ContentWorkguideToggle from '../../components/ContentWorkguideToggle/ContentWorkguideToggle';
/**
 * Notes:
 *  verMap: represents the version of each main versioned subcomponents
 *      0: Not expanded content identity
 *      1: Content identity is expanded to cover several components
 */
const Content = ({ productName, user, setUser, addMessages, bannerActionReminder=true, promoContainerRef=null, verMap={} }) => {
    const DEFAULT_CONSUME_OPTION_QUANTITY = 1;
    const POP_UP_WIDTH = 380; //450px
    const SCROLLY_ACTION_REMINDER_TRIGGER = window.innerHeight // TODO: Now we are using 100vh
    const PATH_TO_CLASSROOM = `/${process.env.REACT_APP_CLASS_SUBDOMAIN}/learn`;
    const PATH_TO_HOME = '/';
    const { t } = useTranslation('contentProduct');
    const theme = useTheme();
    const localeCtx = useLocaleCtx();
    const countryAndCurrencyCtx = useCountryAndCurrencyCtx();
    const displayablePromoCodeCtx = useDisplayablePromoCodeCtx();
    const matchMobile = useMediaQuery('(max-width:680px)');
    const location = useLocation();
    const history = useHistory();
    const redirectApi = useRedirect();
    const query = new URLSearchParams(location.search)
    // It is null if there is no such a query in the url
    // const purchaseStatus = query.get('redirect_status');
    // const [purchaseStatus, setPurchaseStatus] = useState(purchaseStatus)
    // const paymentIntentClientSecret = query.get('payment_intent_client_secret'); 
    // const redirectSource = query.get('source_type'); 
    const paymentIntentId = query.get('payment_intent');  
    const [showPurchaseModal, setShowPurchaseModal] = useState(paymentIntentId !== null);
    const [activeOpenPromo, setActiveOpenPromo] = useState(null)
    const [showPromo, setShowPromo] = useState(false);
    const [contentProduct, setContentProduct] = useState(null);
    const [contentStructurePreview, setContentStructurePreview] = useState(null);
    const [cachedProductConsumptionOptionMap, setCachedProductConsumptionOptionMap] = useState(null);
    const [filteredProductConsumptionOptionMap, setFilteredProductConsumptionOptionMap] = useState(null);
    const [productConsumptionStatusDetails, setProductConsumptionStatusDetails] = useState(null);
    const [contentPage, setContentPage] = useState(null);
    const isBannerActionReminderShown = useScrollTrigger({ disableHysteresis:true, threshold: SCROLLY_ACTION_REMINDER_TRIGGER });
    const [isSticky, setIsStickyRef] = useDetectSticky();
    const [userAccessToProductDetails, setUserAccessToProductDetails] = useState(null);
    const [showContactAdmissions, setShowContactAdmissions] = useState(false);
    const [showAddToSubscriptionModal, setShowAddToSubscriptionModal] = useState(false);
    const [isRetrivingError, setIsRetrivingError] = useState(false);
    const [isRetrivingConsumptionInfoError, setIsRetrivingConsumptionInfoError] = useState(false);
    const [productIdentifierToConsumeActions, setProductIdentifierToConsumeActions] = useState(null);
    const { requestUserProductNotificationArray, setUserProductNotification } = useUpdateUserProductNotificationCtx();
    const { isUserProductNotificationSet, userProductNotificationMap } = useUserProductNotificationCtx();
    const [isReleasedNotificationSet, setIsReleasedNotificationSet] = useState(false);
    const [isConsumableNotificationSet, setIsConsumableNotificationSet] = useState(false);
    const [isConsumeButtonLoading, setIsConsumeButtonLoading] = useState(false);
    const consumeButtonProps = useMemo(() => {
        let props;
        if(isNotEmptyObject(productConsumptionStatusDetails) && isNotEmptyObject(filteredProductConsumptionOptionMap) && userAccessToProductDetails){
            let label;
            let actionFn;
            let disabled;
            let onClick;
            const productConsumptionOption = filteredProductConsumptionOptionMap.main || {}
            // const defaultJoinActionFn = () => handleShowPurchaseModal(true, productDetails, DEFAULT_INITIAL_QUANTITY);
            const mainProductIdentifier = {
                productId: productConsumptionOption.id,
                editionNum: productConsumptionOption.editionNum
            };
            const defaultJoinActionFn = () => handleShowPurchaseModal(true);
            const subscribeActionFn = () => handleShowAddToSubscriptionModal(true);
            // const subscribeActionFn = () => requestAddProductToUserSubscription(user.basicData.id, mainProductIdentifier, DEFAULT_COUNSUME_OPTION_QUANTITY);
            let userAccessNeedsConsumption = true;
            let noSubscribedButSubscriptable = false;
            if(isNotEmptyObject(userAccessToProductDetails)){
                const noAccessType = getFromSafeObject(userAccessToProductDetails, 'details.noAccessType');
                const isAccessInterrupted = userAccessToProductDetails.isAccessInterrupted;
                if(userAccessToProductDetails.hasAccess){
                    label = t('common:continue')
                    actionFn = () => history.push(`${PATH_TO_CLASSROOM}/${contentProduct.id}`)
                }else if(isAccessInterrupted){
                    if(noAccessType === 'noAvailable'){
                        // TODO: Let the user know when the content is available again
                        label = t('common:noAvailable')
                        actionFn = () => history.push(`${PATH_TO_CLASSROOM}/${contentProduct.id}`)
                        disabled = true
                    }else if(noAccessType === 'noReleased'){
                        label = isReleasedNotificationSet ? t('common:availableSoon') : t('common:notifyMe')
                        actionFn = async () => handleSetUserProductNotification('isReleased');
                        disabled = isReleasedNotificationSet 
                    }
                }else if(noAccessType === 'noSubscribedButSubscriptable'){
                    noSubscribedButSubscriptable = true
                }
                    // const noAccessTypeByConsumptionNeeds = ['noSubscriptableAtDate, noActiveSubscription', 'noEnoughSubscriptionLevel', 'noSubscriptable']
                    // // if(noAccessType === 'noSubscribedButSubscriptable'){
                    // //     // GO TO Try to include it in subscription
                    // //     label = t('common:addToMySubscription')
                    // //     actionFn = subscribeActionFn;
                    // // }else if(noAccessTypeByConsumptionNeeds.includes(noAccessType)){
                    //     // // if(productConsumptionStatusDetails.isConsumable){
                    //     //     actionFn = defaultJoinActionFn;
                    //     //     label = t('common:joinNow')
                    //     // // }
                    // }else if(noAccessType === 'noAvailable' && accessType){
                    //     // TODO: Let the user know when the content is available again
                    //     label = t('common:noAvailable')
                    //     actionFn = () => history.push(`${PATH_TO_CLASSROOM}/${contentProduct.id}`)
                    //     disabled = true
                    // }else if(noAccessType === 'noReleased' && accessType){
                    //     // TODO: Let the user know when the content is released
                    //     label = t('common:availableSoon')
                    //     actionFn = () => {}
                    //     disabled = true
                    // }
                    // // }else{
                    // //     // no exists
                    // // }
                // }
                userAccessNeedsConsumption = label === undefined & actionFn === undefined;
            }
            if(userAccessNeedsConsumption){
                if(productConsumptionStatusDetails.isConsumable){
                    if(noSubscribedButSubscriptable){
                        if(productConsumptionStatusDetails.details.isSubscriptable){
                            label = t('common:addToSubscription')
                            actionFn = subscribeActionFn;
                        }else{
                            // TODO: let the user know it is not subscriptable now
                        }
                    }else{
                        actionFn = defaultJoinActionFn;
                        label = t('common:joinNow')
                    }
                }else{
                    const consumabilityStatus = getFromSafeObject(productConsumptionStatusDetails,'details.consumabilityStatus');
                    // if(consumabilityStatus === 'noUnitsLeft' || consumabilityStatus === 'noAvailEdition'){

                    // }
                    label = isConsumableNotificationSet ? t('common:noAvailable') : t('common:notifyMe')
                    actionFn = async () => handleSetUserProductNotification('isConsumable');
                    disabled = isConsumableNotificationSet 
                }
            }

            if(actionFn){
                onClick = (e) => {
                    e.preventDefault();
                    actionFn();
                }
            }
            props = {label, disabled, onClick};
        }else{
            props = null;
        }
        return props
    },[userAccessToProductDetails, productConsumptionStatusDetails, filteredProductConsumptionOptionMap, isReleasedNotificationSet, isConsumableNotificationSet])

    const styles = {
        bannerActionReminder:
            // -webkit-backdrop-filter: blur(15px);
            // backdrop-filter: blur(15px);
            isSticky ? 
                {
                    WebkitBackdropFilter: 'blur(15px)',
                    backdropFilter: 'blur(15px)',
                    backgroundColor: 'rgba(255,255,255, 0.2)',
                }
                :
                {
                    color: theme.palette.text.contrast,
                    backgroundColor: theme.palette.background.dark,
                }
        ,
        contentProfessors:{
            noLeadSubtitleStyle:{
                // color: theme.palette.primary.darker,
                // color: contentPage.palette.primary.contrastText,
            },
            leadProfessorContainerStyle:{
                position: 'initial'
            },
            noLeadProfessorsContainerStyle:{
                backgroundColor: theme.palette.primary.lighter,
                // backgroundColor: contentPage.palette.primary.main,
                // color: contentPage.palette.primary.contrastText
            },
            noLeadProfessorPaperStyle:{
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[1],
            }
        },
        contentPerksAndJoinOptions:{
            background: theme.palette.surface.light[1]
        },
        recommendedContent:{
            background: theme.palette.surface.light[1]
        }
    }

    const setDefaultProductIdentifierToConsume = () => {
        setProductIdentifierToConsumeActions({
            productId: contentProduct.id,
            // editionNum: contentProduct.editionNum
        })
    }

    const handleShowPurchaseModal = (show) => {
        setShowPurchaseModal(show);
        if(show === false){
            setDefaultProductIdentifierToConsume();
        }
    }

    const handleShowAddToSubscriptionModal = (show) => {
        setShowAddToSubscriptionModal(show);
    }
    
    const handleOnContactAdmissions = (e) => {
        e.preventDefault();
        setShowContactAdmissions(pre => !pre);
    }

    const handleOnConsumptionOptionClick = async (productConsumptionOption) => {
        setProductIdentifierToConsumeActions({
            productId: productConsumptionOption.id
            // editionNum: 
        })
        handleShowPurchaseModal(true)
        return 
    }

    const handleOnRecommendedContentProductClick = (contentProduct) => {
        const productTypeName = getFromSafeObject(contentProduct, 'details.product.productTypeName');
        const contentTypeName = contentProduct?.contentTypeName;
        const productName = getFromSafeObject(contentProduct, 'details.product.name');
        if(productTypeName && productName){
            history.push(`/${productTypeName}/${productName}`)
        }else{
        }
    }

    const handleSetUserProductNotification = async (type) => {
        setIsConsumeButtonLoading(true);
        await setUserProductNotification(type, productIdentifierToConsumeActions, !isReleasedNotificationSet);
        setIsConsumeButtonLoading(false);
    }
    
    const handleClosePromo = (e) => {
        e.preventDefault();
        setShowPromo(false);
    }

    // const requestAddProductToUserSubscription = async(userId, productDetails, quantity, abortSignal) => {
    //     const setRefreshedUser = async (setUser, signal) => {
    //         const user = await refreshUser(signal);
    //         if(!signal || !signal.aborted){
    //             justSetUser(user, setUser);
    //         }
    //     }   
    //     let productName;
    //     let forceRefresh;
    //     try{
    //         const selectedProductConsumptionOption = cachedProductConsumptionOptionMap.all[productDetails.productId];
    //         productName = getFromSafeObject(selectedProductConsumptionOption, 'description.nameTranslated');
    //         triggerEventWithData('loadingPopUp', {show:true, message:`${textTransform('title', t('addingToSubscription'))} ...`});
    //         const addDetails = {
    //             quantity,
    //             effectiveAt: getInsertableDate(new Date())
    //         };
    //         await addProductToUserSubscription(userId, productDetails, addDetails, 1000, abortSignal);
    //         try{
    //             throw new Error();
    //             await setRefreshedUser(setUser, abortSignal);
    //             addMessages([{
    //                 message: `${textTransform('title', productName ? t('common:addToSubscriptionFeedback.success.productNameAddedToSubscription', { productName }) : t('common:addToSubscriptionFeedback.success.selectedProductAddedToSubscription') )}.`,
    //                 severity: 'success'
    //             }])
    //         }catch(error){
    //             forceRefresh = true;
    //         }
    //     }catch(error){
    //         let message = `${textTransform('title', productName ? t('common:addToSubscriptionFeedback.error.productNameCannotAddToSubscription', { productName }) : t('common:addToSubscriptionFeedback.error.selectedProductNoAddedToSubscription') )}.`  
    //         let severity = 'error';
    //         const customError = getCustomErrorOrUndefined(error);
    //         if(customError){
    //             const userProductSubscriptabilityDetails = getFromSafeObject(customError, 'payload.userProductSubscriptabilityDetails');
    //             // TODO: implement filter error message based on noSubscriptabilityType
    //         }
    //         addMessages([{
    //             message,
    //             severity
    //         }])
    //     }finally{
    //         triggerEventWithData('loadingPopUp', {show:false });
    //         if(forceRefresh) redirectApi.refreshRoute()
    //     }
    // }

    const requestContentPage = async(name, localeId, abortSignal) => {
        try{
            const res = await getProductPage(name, localeId, 0, abortSignal);
            const {
                product,
                productPage,
            } = res.data;
            if(!abortSignal.aborted){
                setContentProduct(prev => product);
                setContentPage(prev => productPage);
            }
        }catch(error){
            if(!abortSignal.aborted){
                // TODO: Process relevant error except CustomError sr_404
                const customError = getCustomErrorOrUndefined(error);
                if(customError && customError.cause === 'sr_404'){
                    // Product not found cannot show page
                    redirectApi.redirectToRoute('/404', {}, true)
                }else{
                    setIsRetrivingError(true);
                }
            }
        }
    }

    const requestContentStructurePreview = async(name, localeId, abortSignal) => {
        try{
            const query = { defaultContentSystem : true }; // Retrieve only default system version
            // const query = {}; // Retrieve all versions
            const res = await getContentProductStructurePreview(name, localeId, '', query, 0, abortSignal);
            const {
                contentStructurePreview
            } = res.data;
            if(!abortSignal.aborted){
                setContentStructurePreview(prev => contentStructurePreview);
            }
        }catch(error){
            // TODO: Process relevant error 
        }
    }

    const requestContentConsumptionDetails =  async(name, {countryCode, stateCode, currencyId}, abortSignal=null) => {
        try{
            const productDetails = {name, quantity:1}
            const res = await getProductConsumptionDetailsCollection([productDetails], {countryCode, stateCode, currencyId}, {}, 0, abortSignal)
            const {
                productConsumptionDetailsCollection,
            } = res.data
            const productConsumptionDetails = productConsumptionDetailsCollection[0];
            if(productConsumptionDetails){
                const productConsumptionStatusDetails = getProductConsumptionStatusDetails(productConsumptionDetails);
                if(!abortSignal.aborted){
                    setProductConsumptionStatusDetails(prev => productConsumptionStatusDetails)
                }
            }
        }catch(error){
            // TODO:implement
            if(!abortSignal.aborted){
                setIsRetrivingConsumptionInfoError(true);
            }
        }
    }

    const requestContentConsumptionOptionMap = async(name, {countryCode, stateCode, currencyId}, localeId, abortSignal=null) => {
        try{
            const res = await getProductConsumptionOptionMap(name, {countryCode, stateCode, currencyId}, localeId, 0, abortSignal);
            const productConsumptionOptionMap = res.data;
            if(!abortSignal.aborted){
                setCachedProductConsumptionOptionMap(prev => productConsumptionOptionMap)
            }
        }catch(error){
            // TODO: implement
            if(!abortSignal.aborted){
                setIsRetrivingConsumptionInfoError(true)
            }
        }
    }

    // const requestUserContentProductProgress = async(userId, productId, abortSignal) => {
    //     try{
    //         const res = await getUserContentProgress(userId, productId, { currentSystem:true }, 0, abortSignal);
    //         const {
    //             userContentProductProgress
    //         } = res.data
    //         if(!abortSignal.aborted){
    //             setUserContentProductProgress(userContentProductProgress)
    //         }
    //     }catch(error){
    //         // Set to empty object the user and notify about the error
    //         setUserContentProductProgress({});
    //         // TODO: Notify about the error
    //     }
    // }

    const requestUserAccessToProductDetails = async( userId, productDetails, abortSignal) => {
        try{
            const res = await getUserAccessToProductDetailsCollection(userId, [productDetails], false, 0, abortSignal);
            const {
                userAccessToProductDetailsCollection
            } = res.data;
            const userAccessToProductDetails = userAccessToProductDetailsCollection[0] || {} 
            if(!abortSignal.aborted){
                setUserAccessToProductDetails(prev => userAccessToProductDetails);
            }
        }catch(error){
            if(!abortSignal.aborted){
                // Set to empty object the user and notify about the error
                setUserAccessToProductDetails(prev => {});
                addMessages([{
                    message: `${textTransform('title',t('common:weCouldNotAccessToYourUserInformation'))}. ${textTransform('title',t('common:infoRelatedToYourAccountCouldBeOutdated'))}`,
                    severity:'warning'
                }])
            }
        }
    }

    // const requestUserProductPurchasability = async(userId, productConsumptionOptionMap, abortSignal) => {
    //     try{
    //         const productDetailsArray = Object.values(productConsumptionOptionMap.alt).forEach(productTypeConsumption => {
    //             productTypeConsumption.productConsumptionOptionArray.map(productConsumptionOption => )
    //         })
    //         const res = await getUserProductPurchasabilityDetailsCollection(userId, productDetailsArray, true, 0, abortSignal);
    //         const {
    //             productId,
    //             productConsumptionOptionMap,
    //             productConsumptionDetailsByIdMap,
    //             userProductPurchasabilityDetailsByIdMap
    //         } = res.data;
    //         if(!abortSignal.aborted){
    //             setProductConsumptionOptionMap(productConsumptionOptionMap);
    //             setUserProductPurchasabilityDetailsByIdMap(userProductPurchasabilityDetailsByIdMap);
    //             // setProductConsumptionStatusDetails(productConsumptionStatusDetails)
    //         }
    //     }catch(error){
    //         // TODO: implement
    //         console.log(error)
    //     }
    // }

    const getContentPreviewAndFeaturesComponent = (contentPreviewAndFeaturesVer) => {
        let Component;
        switch(contentPreviewAndFeaturesVer){
            case '1':
                Component = 
                    <>
                        <ContentPreviewDescriptionFeaturesAndMultiSystem 
                            contentPage={contentPage} 
                            contentProductImgURI={contentPage.contentDescription?.imgURI || contentProduct.imageDetails?.main?.publicId}
                            contentCompatibleDeviceArray={getFromSafeObject(contentProduct, 'summaryDetails.availableConfiguration.deviceArray')}
                            contentDeviceVersionArray={getFromSafeObject(contentProduct, 'summaryDetails.availableConfiguration.systemLessonVersionArray')}
                            contentStructure={contentStructurePreview}
                        />
                        {contentPage.contentFeaturedFeatures?.hasWorkguide || false ?
                            <ContentWorkguideToggle />
                            :
                            null
                        }
                    </>
                break
            case '0':
            default:
                Component = 
                <>
                    <ContentPreviewAndDescription 
                        contentPage={contentPage} 
                        contentProductImgURI={contentPage.contentDescription?.imgURI || contentProduct.imageDetails?.main?.publicId}
                    /> 
                    <ContentFeatures 
                        contentPage={contentPage} 
                        contentCompatibleDeviceArray={getFromSafeObject(contentProduct, 'summaryDetails.availableConfiguration.deviceArray')}
                        contentDeviceVersionArray={getFromSafeObject(contentProduct, 'summaryDetails.availableConfiguration.systemLessonVersionArray')}
                        contentStructure={contentStructurePreview}
                    />
                </>
        }       
        return Component;
    }

    useEffect(() => {
        if(contentProduct?.name !== productName || contentProduct?.description?.localeId != localeCtx.localeId){
            const resetPreviousContentProduct = () => {
                setContentProduct(null);
                setContentPage(null);
                setContentStructurePreview(null);
            }
            resetPreviousContentProduct()
            const abortController = new AbortController();
            setIsRetrivingError(false);
            requestContentPage(productName, localeCtx.localeId, abortController.signal);
            requestContentStructurePreview(productName, localeCtx.localeId, abortController.signal);    
            requestContentConsumptionOptionMap(productName, countryAndCurrencyCtx, localeCtx.localeId, abortController.signal);
            requestContentConsumptionDetails(productName, countryAndCurrencyCtx, abortController.signal);
            return () => {
                //cleanup
                abortController.abort();
            }
        }
    }, [productName, localeCtx.localeId])

    useEffect(() => {
        if(contentProduct?.name !== productName || contentProduct?.description?.localeId != localeCtx.localeId || countryAndCurrencyCtx.currencyId != productConsumptionStatusDetails.currencyId){
            const resetPreviousContentProduct = () => {
                setFilteredProductConsumptionOptionMap(null);
                setProductConsumptionStatusDetails(null);
                setCachedProductConsumptionOptionMap(null);
                setProductIdentifierToConsumeActions(null);
            }
            resetPreviousContentProduct()
            const abortController = new AbortController();
            setIsRetrivingConsumptionInfoError(false);
            requestContentConsumptionOptionMap(productName, countryAndCurrencyCtx, localeCtx.localeId, abortController.signal);
            requestContentConsumptionDetails(productName, countryAndCurrencyCtx, abortController.signal);
            return () => {
                //cleanup
                abortController.abort();
            }
        }
    }, [productName, localeCtx.localeId, countryAndCurrencyCtx])

    useEffect(() => {
        if(activeOpenPromo && promoContainerRef && promoContainerRef.current != null){
            setShowPromo(true);
        }else{
            setShowPromo(false);
        }
    },[activeOpenPromo])

    useEffect(() => {
        if(contentProduct?.id){
            if(getFromSafeObject(user, 'basicData.id')){
                const abortController = new AbortController();
                const productDetails = {
                    productId: contentProduct.id,
                    // editionNum: contentProduct.editionNum,
                };
                // requestUserContentProductProgress(user.basicData.id, productDetails.productId, abortController.signal);
                requestUserAccessToProductDetails(user.basicData.id, productDetails, abortController.signal);
                requestUserProductNotificationArray([productDetails], abortController.signal);
                return () => {
                    //cleanup
                    abortController.abort();
                }
            }else{
                // setUserContentProductProgress({});
                setUserAccessToProductDetails({});
            }
        }else{
            // setUserContentProductProgress(null);
            setUserAccessToProductDetails(null);
        }
    },[user, contentProduct])

    useEffect(() => {
        if(isNotEmptyObject(contentProduct)){
            setDefaultProductIdentifierToConsume()
        }
    }, [contentProduct])

    useEffect(() => {
        if(cachedProductConsumptionOptionMap && isNotEmptyObject(cachedProductConsumptionOptionMap.alt)){
            const filteredAltConsumptionOption = getFilteredTempConsumptionOptionCategoryMapToUserSubscription(cachedProductConsumptionOptionMap.alt, user);
            setFilteredProductConsumptionOptionMap({main: cachedProductConsumptionOptionMap.main, alt: filteredAltConsumptionOption})
        }
    },[user, cachedProductConsumptionOptionMap])


    useEffect(() => {
        let activePromoCode = null
        if(isNotEmptyObject(displayablePromoCodeCtx) && isNotEmptyObject(contentProduct)){
            const isDisplayable = isPromoCodeDisplayableInProductPage(contentProduct.id, contentProduct.productTypeName, displayablePromoCodeCtx);
            if(isDisplayable){
                activePromoCode = displayablePromoCodeCtx;
            }
        }
        setActiveOpenPromo(activePromoCode);
    },[displayablePromoCodeCtx, contentProduct])

    useEffect(() => {
        setIsReleasedNotificationSet(isUserProductNotificationSet('isReleased', productIdentifierToConsumeActions))
        setIsConsumableNotificationSet(isUserProductNotificationSet('isConsumable', productIdentifierToConsumeActions))
    },[userProductNotificationMap, productIdentifierToConsumeActions])

    return (
            <>
                {isRetrivingError || isRetrivingConsumptionInfoError ?
                        <div className='content-error-wrapper'>
                            <ResourcePageError errorDetails={{requestingDataError:true}} resourceType={'content'} redirectDetails={{ path:PATH_TO_HOME, label:t('common:goToHome')}} />
                        </div>
                    :
                    contentPage && contentProduct && productConsumptionStatusDetails && userAccessToProductDetails && consumeButtonProps ?
                            <>
                                { showAddToSubscriptionModal ?
                                    <AddToSubscriptionModal 
                                        user={user}
                                        showModal={showAddToSubscriptionModal}
                                        setUser={setUser}
                                        width={POP_UP_WIDTH}
                                        productIdentifier={productIdentifierToConsumeActions}
                                        onClickClose={() => handleShowAddToSubscriptionModal(false)}
                                        onSuccess={(productOptionArray) => {
                                            const messageArray = [];
                                            for(let productOption of productOptionArray){
                                                const productName = getFromSafeObject(productOption, 'description.nameTranslated');
                                                messageArray.push({
                                                    severity:'success',
                                                    message: textTransform('title', t("productNameAddedToSubscription", { productName }))
                                                })
                                            };
                                            addMessages(messageArray)
                                        }}
                                    />
                                    :
                                    null
                                }
                                { showPurchaseModal && productIdentifierToConsumeActions ?
                                    <PurchaseProcessModal 
                                        user={user} 
                                        setUser={setUser}
                                        showModal={showPurchaseModal} 
                                        productIdentifier={productIdentifierToConsumeActions}
                                        quantity={DEFAULT_CONSUME_OPTION_QUANTITY}  
                                        onClose={() => handleShowPurchaseModal(false)} addMessages={addMessages} 
                                    />
                                    :
                                    null
                                }
                                { showContactAdmissions ?
                                    <PopUpContactSupport 
                                        user={user} 
                                        showModal={showContactAdmissions} 
                                        onClickClose={handleOnContactAdmissions} 
                                        width={POP_UP_WIDTH}
                                        categoryObject={{defaultKey:'admissions', categoryArray: ['admissions']}}
                                        supportContext={{
                                            productType: contentProduct.productTypeName,
                                            productId: contentProduct.id,
                                        }}
                                    />
                                    :
                                    null
                                }
                                <ContentHero 
                                    user={user}
                                    contentInfo={{
                                        id: contentProduct.id,
                                        contentTypeName: getFromSafeObject(contentProduct, 'summaryDetails.contentTypeName'),
                                        contentTypeNameTranslated:getFromSafeObject(contentProduct,'summaryDetails.contentTypeTranslation.nameTranslated'),
                                        nameTranslated:getFromSafeObject(contentProduct,'description.nameTranslated'),
                                        professorArray:getFromSafeObject(contentProduct,'summaryDetails.professorArray'),
                                        localeArray:getFromSafeObject(contentProduct,'summaryDetails.availableConfiguration.localeArray'),
                                        captionsLocaleArray:getFromSafeObject(contentProduct,'summaryDetails.availableConfiguration.captionsLocaleArray'),
                                        totalNumInscribed:undefined,
                                        totalNumCompleted:undefined,
                                    }}
                                    productConsumptionStatusDetails={productConsumptionStatusDetails}
                                    catchTxt={{
                                        catch: contentPage.catch,
                                        catchDescription: contentPage.catchDescription
                                    }}
                                    showPrimaryButton={ consumeButtonProps.label }
                                    showSecondaryButton={ !matchMobile && !getFromSafeObject(userAccessToProductDetails,'hasAccess') }
                                    primaryButtonProps={{ ...consumeButtonProps, isLoading:isConsumeButtonLoading }}
                                    secondaryButtonProps={{
                                            label: t('common:contactAdmissions'),
                                            onClick:handleOnContactAdmissions
                                        }}
                                />
                                <ContentBasicFeatures 
                                    contentTypeName={getFromSafeObject(contentProduct, 'summaryDetails.contentTypeName')} 
                                    contentFeatures={{
                                        outcomeTypeName: getFromSafeObject(contentProduct,'summaryDetails.outcomeTypeName'),
                                        contentTypeNameTranslated: getFromSafeObject(contentProduct,'summaryDetails.contentTypeTranslation.nameTranslated'),
                                        knowledgeLevelName: getFromSafeObject(contentProduct, 'summaryDetails.knowledgeLevelName'),
                                        lengthInMin: getFromSafeObject(contentProduct, 'summaryDetails.lengthInMin')
                                    }}
                                />
                                {getContentPreviewAndFeaturesComponent(verMap.contentPreviewAndFeatures)}
                                <SingleContentItinerary 
                                    title={t("singleContentItinerary.ourItinerary")} 
                                    contentStructure={contentStructurePreview}
                                    {...isNotEmptyObject(contentPage.contentItinerary?.config) ? {
                                            maxNumLessonToShow: contentPage.contentItinerary.config.maxNumLessonToShow,
                                            numDefaultLessonToShow: contentPage.contentItinerary.config.numDefaultLessonToShow,
                                            numDefaultSectionToShow: contentPage.contentItinerary.config.numDefaultSectionToShow,
                                        }
                                        :
                                        {}
                                    }
                                    {... contentPage.contentDossierFile?.fileURI ? {
                                            itineraryDownloadFile:{
                                                fileURI: contentPage.contentDossierFile.fileURI,
                                                fileName: `${(getFromSafeObject(contentProduct,'description.nameTranslated'))}_qxi_${t('dossier')}.pdf`,
                                                onErrorDownload:((error) => addMessages([{ message:textTransform('title',t('common:errorDownloadingTheFile')) , severity:'error'}]))
                                            }
                                        }
                                        :
                                        {}
                                    }
                                />
                                <ContentSocialComments contentPage={contentPage} /> 
                                <ContentProfessors 
                                    title={t("contentProfessors.meetYourInstructor", {count: getFromSafeObject(contentProduct,'details.professorArray')?.length || 0})}
                                    noLeadSubtitle={t("contentProfessors.otherInstructorsYouLearnFrom")} 
                                    professorArray={getFromSafeObject(contentProduct,'summaryDetails.professorArray')} 
                                    leadProfessorContainerStyle={styles.contentProfessors.leadProfessorContainerStyle}
                                    noLeadProfessorsContainerStyle={styles.contentProfessors.noLeadProfessorsContainerStyle}
                                    noLeadProfessorPaperStyle={styles.contentProfessors.noLeadProfessorPaperStyle}
                                    noLeadSubtitleStyle={styles.contentProfessors.noLeadSubtitleStyle}
                                />
                                {filteredProductConsumptionOptionMap && isNotEmptyObject(filteredProductConsumptionOptionMap) && (userAccessToProductDetails == null || !userAccessToProductDetails.hasAccess) ?
                                    <NestSections 
                                        style={styles.contentPerksAndJoinOptions}
                                        {... isNotEmptyObject(filteredProductConsumptionOptionMap.main) ? 
                                            {
                                                topClassName:'nest-sections-top-container',
                                                bottomClassName:'nest-sections-bottom-container'
                                            }
                                            :
                                            {}
                                        }
                                    >
                                        {isNotEmptyObject(filteredProductConsumptionOptionMap.main) ?
                                            <ContentPurchasePerks 
                                                perksToHideMap={{
                                                    discount: !(productAmountHasDiscountCouponApplied(getFromSafeObject(filteredProductConsumptionOptionMap.main,'consumptionDetails.details.purchasabilityDetails.details.amount')) )
                                                }}
                                            />
                                            :
                                            null
                                        }
                                        <ContentConsumptionOptions
                                            user={user} 
                                            productConsumptionOptionMap={filteredProductConsumptionOptionMap}
                                            onClickProductConsumptionOption={handleOnConsumptionOptionClick}
                                            recommendedConsumptionTypeOrderArray={contentPage.recommendedConsumptionTypeOrderArray}
                                        />
                                    </NestSections>
                                    :
                                    null
                                }
                                <ContentFAQ contentPage={contentPage} />
                                <ContactSupportSection 
                                    user={user}
                                    categoryObject={{
                                        categoryArray: ['admissions', 'contentsAndPrograms', 'productsAndPrices', 'subscriptions', 'other'],
                                        defaultKey: 'contentsAndPrograms'
                                    }}
                                    supportContext={{
                                        productId: contentProduct.id,
                                        productType: contentProduct.productTypeName,
                                        // editionNum: contentProduct.editionNum
                                    }} 
                                />
                                <RecommendedContentSection
                                    product={contentProduct}
                                    subtitle={t("recommendedContent.recommendedContent")}
                                    wrapperProps={{style:styles.recommendedContent}}
                                    onContentClick={handleOnRecommendedContentProductClick}
                                />
                                <MailingListSubscriptionSection 
                                    user={user}
                                    subscriptionListArray={[
                                        {
                                            name:'Newsletter', customSource:'content-page', isRequired:true
                                        },
                                        {
                                            name:'Marketing', customSource:'content-page',
                                        },
                                    ]}
                                />
                                {bannerActionReminder &&
                                    <>
                                        <div 
                                            className={`content-banner-action-reminder-wrapper${isBannerActionReminderShown ? ' show' : ''}`}
                                            ref={setIsStickyRef}
                                            style={ styles.bannerActionReminder}
                                        >
                                            <BannerActionReminder 
                                                buttonProps={{...consumeButtonProps, ...!isSticky && consumeButtonProps.disabled ? {onDark: true} : {}}}
                                                {...productConsumptionStatusDetails.isScarce ?
                                                    {
                                                        actionScarcity:{
                                                            isScarce: productConsumptionStatusDetails.isScarce,
                                                            count: productConsumptionStatusDetails.details.availUnits,
                                                            unitType:t('common:place', { count: productConsumptionStatusDetails.details.availUnits })
                                                        }
                                                    }
                                                    :
                                                    {}
                                                }
                                                {...productConsumptionStatusDetails.isPresale ? 
                                                    productConsumptionStatusDetails.details.consumableDateStatus === 'noDate' ? 
                                                            {
                                                                actionTitle: `${textTransform('title',t('common:comming'))} ${t('common:symbolClock')}`,
                                                                actionDate: getSafeDateOrUndefined(productConsumptionStatusDetails.details.releaseDate)
                                                            }
                                                        :
                                                            {
                                                                actionTitle: `${textTransform('title',t('common:release'))} ${t('common:symbolParty')}`,
                                                                actionDate: getSafeDateOrUndefined(productConsumptionStatusDetails.details.releaseDate)
                                                            }
                                                    :
                                                    {
                                                        actionTitle: getFromSafeObject(contentProduct, 'description.nameTranslated'),
                                                    }
                                                }
                                            />
                                        </div>
                                    </>
                                }
                                {activeOpenPromo && showPromo ?
                                    ReactDOM.createPortal(
                                        <BannerPromo 
                                            promo={activeOpenPromo}
                                            txt={{
                                                name: `${getFromSafeObject(activeOpenPromo,'details.occurrence.details.displayability.description.title')} ${t('symbolParty')}`,
                                                description: getFromSafeObject(activeOpenPromo,'details.occurrence.details.displayability.description.description'),
                                            }}
                                            endsDate={getSafeDateOrUndefined(getFromSafeObject(activeOpenPromo,`details.occurrence.endsDate`))}
                                            onClose={handleClosePromo}
                                        />
                                    , promoContainerRef.current)
                                    :
                                    null
                                }
                            </>
                        :
                            <div className='content-loading-wrapper'>
                                <LoadingComponent  visibleElements='circle' />
                            </div>
                }
                <BigFooterNoLinks user={user} />
            </>
    )
}

export default Content