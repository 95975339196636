import { styled } from "@mui/system";
// import { styled } from '@material-ui/core' 

/**
 * styled-component li element custom version 
 *  Note: styled from material ui 5.0 throws a warning using transient props if it is not imported
 *  from 'styled-components' instead of material-ui. 
 *  I have decided to use emotions from the new version of material ui which handle the transient props
 *  through the shouldForwardProp function
 *  Ref: 
 *      https://stackoverflow.com/questions/72715672/transient-props-react-warring-invalid-attribute-name
 *      https://stackoverflow.com/questions/71128841/mui-system-how-to-pass-transient-props-to-styled
 */

const CustomLi = styled('li', {
    shouldForwardProp: (prop) => prop !== "marker"
})(({ marker }) => ({
    "&::marker": marker ? {...marker} : {}
}))

export default CustomLi