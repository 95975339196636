import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import UserSystemSelectFormItem from '../UserSystemSelectFormItem/UserSystemSelectFormItem';
import './setUserSystemAsDefaultDialog.css'
import { setDefaultUserSystem } from '../../services/userServices';
import ToggleMsg from '../ToggleMsg/ToggleMsg';
import { isEmptyObject, isNotEmptyObject } from '../../aux/aux';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

const SetUserSystemAsDefaultDialog = ({ userId, userSystem, onDidSet=async()=>{}, onFinish=()=>{}, onRender=()=>{} }) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'setUserSystemAsDefaultDialog' });
    const [isSetting, setIsSetting] = useState(false);
    const [error, setError] = useState({isError:false, msg:null, type:null})
    const abortControllerRef = useRef(null);

    const requestSetDefaultUserSystem = async (userId, userSystemId, signal) => {
        try{
            setIsSetting(true);
            const res = await setDefaultUserSystem(userId, userSystemId, 0, signal);
            const defaultUserSystem = res.data
            if(!signal || !signal.aborted){
                await onDidSet(defaultUserSystem)
                onFinish();
            }
        }catch(error){
            if(!signal || !signal.aborted){
                setError({isError:true, msg:`${t('systemCannotBeSetAsDefaultNow')}. ${t('tryItLater')}`, type:'onSubmit'})
            }
        }finally{
            if(!signal || !signal.aborted){
               setIsSetting(false);
            }
        }
    }
    const handleOnSubmit = async (e) => {
        e.preventDefault();
        await requestSetDefaultUserSystem(userId, userSystem.id, abortControllerRef.current.signal);
    }

    const handleOnCancel = () => {
        onFinish()
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController
        return(() => abortController.abort())
    },[])
    
    useEffect(() => {
        const id = setTimeout(()=>onRender(),300);
        return(()=> clearTimeout(id))
    })

    return (
        <div className='set-user-system-as-default-dialog-main-container'>
            <div className='set-user-system-as-default-title'>
                <Typography variant='body1' align='center'>
                    {t("doYouWantToSetItAsTheDefaultSystem")}
                </Typography>
            </div>
            <div className='set-user-system-as-default-dialog-selected-system-item'>
                {userId != null && isNotEmptyObject(userSystem) ?
                    <div style={{border:"solid 1px #888888", borderRadius:"5px", padding:"5px"}}>
                        <UserSystemSelectFormItem system={userSystem} showCompatibilityLabel={false} showIsSelectedWhenNickName={false} />
                    </div>
                    :
                    <LoadingComponent visibleElements='circle'/>
                }
            </div>
            <form onSubmit={handleOnSubmit}>
                <div className='set-user-system-as-default-dialog-buttons-container'>
                    <div className='set-user-system-as-default-dialog-button-container'>
                        <ButtonLoading 
                            type='submit' 
                            name='actionSubmit' 
                            value='yes' 
                            variant='outlined' 
                            color='primary' 
                            isLoading={isSetting} 
                            label={t("common:yes")}
                            disabled={isSetting || (error.isError && (error.type === 'onSubmit')) || userId == null || isEmptyObject(userSystem)} 
                            fullWidth
                        />
                    </div>
                    <div className='set-user-system-as-default-dialog-button-container'>
                        <Button
                            onClick={handleOnCancel} 
                            name='actionCancel' 
                            value='no' 
                            variant='outlined' 
                            color="secondary" 
                            disabled={isSetting}
                            fullWidth
                        >
                            {t("common:no")}
                        </Button>
                    </div>
                </div>
            </form>
            <ToggleMsg 
                isShown={error.isError} 
                msg={error.msg} 
                type='error' 
                onShowTime={() => {setError(prev => ({...prev, isError:false }))}}
            />
        </div>
    )
}

export default SetUserSystemAsDefaultDialog