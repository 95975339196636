function getSystemFromUserConfig(userConfig){
    return {deviceName:userConfig.selectedDeviceName, softwareName:userConfig.selectedSoftwareName}
}

/**
 *  - length between 6 to 10
 *  - Must contain at least 1: 1) digit, 2) lowercase letter, 3) uppercase letter, and 4)!@#%&*  
 * @param {*} password 
 */
const MIN_PASSWORD_LENGTH = 6;
const MAX_PASSWORD_LENGTH = 10;
const isStrongUserPassword = (password) => {
    const rexString = `^(?=[a-zA-Z0-9!@#%&*]{${MIN_PASSWORD_LENGTH},${MAX_PASSWORD_LENGTH}}$)(?=[^0-9]*[0-9])(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=[^!@#%&*]*[!@#%&*]).*`
    const PASSWORD_REGEX = new RegExp(rexString, 'g');
    return (PASSWORD_REGEX.exec(password) != null)
}

export { 
    getSystemFromUserConfig,
    isStrongUserPassword,
    MIN_PASSWORD_LENGTH,
    MAX_PASSWORD_LENGTH
}