import { useState, useRef } from 'react';
import { Typography } from '@material-ui/core';
import ContentWorkguideVsProtocol from '../ContentWorkguideVsProtocol/ContentWorkguideVsProtocol';
import { useTheme } from '@material-ui/styles';
// Img
import NextIcon from '../../img/next-icon.svg';
import './contentWorkguideToggle.css'
import { useTranslation } from 'react-i18next';
import { textTransform } from '../../aux/aux';

const ContentWorkguideToggle = () => {
    const theme = useTheme();
    const { t } = useTranslation('contentProduct', { keyPrefix: 'contentWorkguideToggle' })
    const [isToggled, setIsToggled] = useState(false);
    const wrapperRef = useRef(null)
    const styles = {
        shrinkableContainer: {
            backgroundColor: theme.palette.surface.light[1],
        },
        buttonContainer:{
            backgroundColor: theme.palette.surface.light[1],
            color: theme.palette.primary.main
        },
        icon: {
            transform: `rotate(${isToggled ? -90 : 90}deg)`,
            height: theme.typography.body2.fontSize
        },
        widget: {
            backgroundColor: theme.palette.surface.light[0]
        }
    }
    const handleOnToggle = (e) => {
        const scrollToWrapper = () => {
            const HEADER_SIZE_PX = 60;
            window.scrollTo({top:wrapperRef.current.offsetTop - HEADER_SIZE_PX})
        }
        e.preventDefault();
        setIsToggled(prev => {
            if(prev){
                scrollToWrapper()
            }
            return !prev
        })
    }
    return (
        <div ref={wrapperRef} className='content-workguide-toggle-wrapper'>
            <div className={`content-workguide-toggle-shrinkable-container${isToggled ? ' toggled': ''}`} style={styles.shrinkableContainer} >
                <ContentWorkguideVsProtocol widgetStyleMap={styles.widget} includeTitle={true} />
            </div>
            <div className='content-workguide-toggle-button-container' onClick={handleOnToggle} style={styles.buttonContainer} >
                <NextIcon className='content-workguide-toggle-button-label-icon' style={styles.icon} />
                <Typography variant='body2' display='inline' style={{paddingLeft: '5px'}}>
                    {textTransform('title', `${isToggled ? t('showLessAboutWorkguides') : t('showMoreAboutWorkguides')}`)}
                </Typography>
            </div>
        </div>
    )
}

export default ContentWorkguideToggle