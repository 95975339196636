import { useEffect, useState } from 'react';
import { Grid, Box, Paper, Typography, Divider, LinearProgress, Button, useMediaQuery } from '@material-ui/core';
import {Alert} from '@mui/material';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Skeleton } from '@mui/material'
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
import { WarningAmber } from '@mui/icons-material';
import { getFromSafeObject, getSafeDateOrUndefined, isNotEmptyObject, textTransform } from '../../aux/aux';
import ContentTypeChip from '../ContentTypeChip/ContentTypeChip';
import LocaleDateTypography from '../LocaleDateTypography/LocaleDateTypography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './userContentProductPreview.css'
import SubscriptionIcon from '../SubscriptionIcon/SubscriptionIcon';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';

const UserContentProductPreview = ({ userContentProductPreview, userAccessToProductDetails }) => {
    const IMG_HEIGHT_PX = 150;
    const IMG_RESOLUTION_SCALE_FACTOR = 3;
    const CLASSROOM_PATH = 'learn'; 
    const localeCtx = useLocaleCtx();
    const CONTENT_PATH = `${CLASSROOM_PATH}/${userContentProductPreview.contentProductId}`;
    const matchMobile = useMediaQuery('(max-width:680px)');
    const { t } = useTranslation('dashboard', {keyPrefix:"userContentProductPreview"});
    const theme = useTheme();
    const [imgURL, setImgURL] = useState(null);
    const [noAccessAlert, setNoAccessAlert] = useState({show:undefined, msg:undefined, severity:undefined})
    const userProgressPercent = getFromSafeObject(userContentProductPreview,'userContentProgress.totalCompletedMin') / getFromSafeObject(userContentProductPreview,'product.typeProduct.lengthInMin');
    const completionDate = getSafeDateOrUndefined(getFromSafeObject(userContentProductPreview, 'userContentProgress.completionDate'));
    const styles = {
        background:{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[1]
        },
        mainContainer:{
            ...(noAccessAlert.msg ? 
                {
                    pointerEvents: 'none',
                    opacity: theme.palette.action.disabledOpacity    
                }
                :
                {}
            )
        },
        imgContainer:{
            height:`${IMG_HEIGHT_PX}px`,
            width:`${IMG_HEIGHT_PX}px`
        },
        img:{
            height:`${IMG_HEIGHT_PX}px`,
            width:`${IMG_HEIGHT_PX}px`
        },
        title:{
            fontWeight: 'bold'
        },
        linearProgress:{
            borderRadius:'4px'
        },
        completionIcon:{
            display: 'block',
            color: theme.palette.success.main,
            fontSize: theme.typography.body1.fontSize,
        },
        isSubscribedContainer:{
            backgroundColor: theme.palette.primary.light
        },
        subscriptionIcon:{
            // color: theme.palette.primary.main
        },
        bottomRowContainer:{
            color:theme.palette.grey[400]
        },
        warningIcon: {
            display:'block',
            fill: theme.palette.warning.main
        }
    }
    useEffect(() => {
        let imgURL;
        const imgIcon = getFromSafeObject(userContentProductPreview, 'product.imageDetails.main');
        
        if(isNotEmptyObject(imgIcon)){
            const imgPublicId = imgIcon.publicId;
            const assetProvider = new ImgDN(cloudinary);
            imgURL = assetProvider.getAssetUrl(imgPublicId, {height: IMG_HEIGHT_PX * IMG_RESOLUTION_SCALE_FACTOR});
        }else{
            imgURL = null;
        }
        setImgURL(imgURL)
    },[userContentProductPreview])

    useEffect(() => {
        if(userAccessToProductDetails && userAccessToProductDetails.hasAccess === false){
            const hasAccessButInterrupted = getFromSafeObject(userAccessToProductDetails, 'details.isAccessInterrupted'); //noAvailable, noReleased
            const noAccessType = getFromSafeObject(userAccessToProductDetails, 'details.noAccessType');
            let msg;
            switch(noAccessType){
                case 'noSubscriptableInDate':
                    msg = t('noAvailableWithSubscription');
                    break;
                case 'noActiveSubscription':
                    msg = t('subscriptionIsInactive');
                    break;
                case 'noEnoughSubscriptionLevel':
                    msg = t('needASubscriptionType', {minimumSubscriptionTypeName: getFromSafeObject(userAccessToProductDetails, 'details.productSubscriptability.type.name')});
                    break;
                case 'noReleased':
                    const releaseDate = getSafeDateOrUndefined(getFromSafeObject(userAccessToProductDetails, 'details.releaseDate'))
                    if(releaseDate){
                        msg = t('availableFromDateOn', {
                            releaseDate: getSafeDateOrUndefined(getFromSafeObject(userAccessToProductDetails, 'details.releaseDate')), 
                            interpolation:{escapeValue: false},
                            formatParams:{
                                releaseDate: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'}
                            }            
                        });
                    }else{
                        msg = t('noAvailablePendingRelease')
                    }
                    break;
                case 'noAvailable':
                default:
                    msg = t('noAvailableAtThisMoment');

            } 
            setNoAccessAlert({show:true, msg:textTransform('title', msg), severity:'warning'});
        }else{
            setNoAccessAlert({show:undefined, msg:undefined, severity:undefined})
        }
    },[userAccessToProductDetails])
    return (
        <div className='user-content-product-preview-wrapper'>
            <div className='user-content-product-preview-wrapper' style={styles.background}>
                <div className='user-content-product-preview-background'/>
                <div className='user-content-product-preview-main-container-wrapper'>
                    {userContentProductPreview.product.description.localeId !== localeCtx.localeId ? 
                            <Alert severity='warning' >
                                {t('translationToTheSelectedLanguageNotAvail')}
                            </Alert>
                        :
                            null
                    }
                    <div className="user-content-product-preview-main-container" style={styles.mainContainer}>
                        <div className='user-content-product-preview-img-container' style={styles.imgContainer}>
                            {imgURL ?
                                <img className="user-content-product-preview-img"  alt="content-img" src={imgURL} style={styles.img}/>
                                :
                                <Skeleton className='user-content-product-preview-img' variant="rectangular" style={styles.img}/>
                            }
                        </div>
                        <div className='user-content-product-preview-info-container'>
                            {isNotEmptyObject(userContentProductPreview) ? 
                                <>
                                    <div className='user-content-product-preview-info-author-container'>
                                        <Typography variant='subtitle2' className='user-content-product-preview-truncated-text'>
                                            {getFromSafeObject(userContentProductPreview,'product.professor.fullName')}
                                        </Typography>
                                        <ContentTypeChip contentTypeName={getFromSafeObject(userContentProductPreview,'product.typeProduct.contentTypeName')} contentTypeNameTranslated={getFromSafeObject(userContentProductPreview,'product.typeProduct.contentTypeTranslation.nameTranslated')} />
                                    </div>
                                    <div className='user-content-product-preview-info-description-container'>
                                        <Typography variant="h6" style={styles.title}>
                                            {getFromSafeObject(userContentProductPreview,'product.description.nameTranslated')}
                                        </Typography>
                                        <Typography variant="body2">
                                            {getFromSafeObject(userContentProductPreview,'product.description.description')}
                                        </Typography>
                                    </div>
                                    <div className='user-content-product-preview-info-progress-container'>
                                        <Typography variant="body2">
                                            {`${textTransform('capitalize', t('totalProgress'))}: ${t('common:{{val, number}}', {style:'percent', val:userProgressPercent, trailingZeroDisplay:'stripIfInteger'})}`}
                                        </Typography>
                                        <div className='user-content-product-preview-info-progress-bar-container'>
                                            {completionDate ? 
                                                <>
                                                    <CheckCircleIcon style={styles.completionIcon} />
                                                    <div className='user-content-product-preview-info-completion-date-container'>
                                                        <Typography variant='body2'>
                                                            {`${textTransform('title',t('completedOn'))}:`}
                                                        </Typography>
                                                        <LocaleDateTypography date={completionDate} variant='body2'/>
                                                    </div>
                                                </>
                                                :
                                                <div className='user-content-product-preview-info-progress-bar'>
                                                    <LinearProgress title={t('progress')} color="primary" variant='determinate' value={userProgressPercent * 100} style={styles.linearProgress}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <Typography variant='h6'>
                                        <Skeleton variant='text' width={'75%'} />
                                    </Typography>
                                    <Typography variant='body2'>
                                        <Skeleton variant='text' width={'50%'}/>
                                    </Typography>
                                </>
                            }
                        </div>
                        {!matchMobile ?
                                <Divider orientation='vertical' flexItem/>
                            :
                                null
                        }
                        <div className='user-content-product-preview-action-container'>
                            {isNotEmptyObject(userContentProductPreview) ? 
                                <Button component={Link} to={CONTENT_PATH} variant="outlined" size="large" color="primary" fullWidth>
                                    {textTransform('title', userContentProductPreview.progress === 0 ? t('common:start') : t('common:continue'))}
                                </Button>
                                :
                                <Skeleton variant='rectangular' width={'100%'} height={'40px'}/>
                            }
                        </div>
                    </div>
                </div>
                {userContentProductPreview.isSubscribed ?
                        <div className='user-content-product-preview-is-subscribed-container' style={styles.isSubscribedContainer}>
                            <SubscriptionIcon subscriptionType={getFromSafeObject(userContentProductPreview,'product.subscriptability.minimumSubscriptionType')} isFilled />
                        </div>
                    :
                        null
                }
                {noAccessAlert.show  ? 
                    <div className='user-content-product-preview-alert-container'>
                        <Alert severity={noAccessAlert.severity} onClose={(e)=>setNoAccessAlert(prev => ({...prev, show:false}))}>
                            {noAccessAlert.msg}
                        </Alert>
                    </div>
                    :
                    null
                }
            </div>
            <div className='user-content-product-preview-bottom-row' style={styles.bottomRowContainer}>
                {getFromSafeObject(userContentProductPreview,'userContentProgress.lastAccessDate') ? 
                        <>
                            <Typography variant='caption'>
                                {`${t('lastAccess')}:`}  
                            </Typography>
                            <LocaleDateTypography date={getFromSafeObject(userContentProductPreview,'userContentProgress.lastAccessDate')} variant='caption'/>
                        </>
                    :
                        null
                }
            </div>
        </div>
    )
}

export default UserContentProductPreview
