import { useEffect, useState, use } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import FeaturedContent from '../FeaturedContent/FeaturedContent'

import { useTheme } from '@material-ui/styles'
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import './landingFeaturedContent.css'
import { getHandSelectedProduct } from '../../services/staticContentServices';
import Carrousel from '../Carrousel/Carrousel';
import SliderBasic from '../SliderBasic/SliderBasic';
import StyledHTMLString from '../StyledHTMLString/StyledHTMLString';

const LandingFeaturedContent = ({ imgLazyLoadThreshold= '600px' }) => {
    const theme = useTheme();
    const { t } = useTranslation('landing', { keyPrefix: 'landingFeaturedContent' });
    const localeCtx = useLocaleCtx();
    const [featuredContentArray, setFeaturedContentArray] = useState(null);
    const matchMobile = useMediaQuery('(max-width:680px)');
    const styles = {
        wrapper: {
            backgroundColor: theme.palette.background.dark,
        },
        title: {
            fontWeight: 'bold',
            color: theme.palette.text.contrast
        }
    }
    
    const requestFeaturedContent = async(localeId, abortSignal) => {
        try{
            const opt = { 
                localeId,
                productTypeName: 'content',
                filteredBy:['featured']
            };
            const res = await getHandSelectedProduct(opt, 0, abortSignal);
            if(!abortSignal.aborted){
                setFeaturedContentArray(res.data);
            }
        }catch(error){
            // TODO: implement
            // set default featuredContent
            // const defaultFeaturedContent = t('defaultFeaturedContent', {returnObjects:true});
            // setFeaturedContentArray([defaultFeaturedContent])
        }
    }
    
    useEffect(() => {
        if(localeCtx.localeId != null){
            const abortController = new AbortController();
            requestFeaturedContent(localeCtx.localeId, abortController.signal);
            return(() => {
                abortController.abort()
            })
        }
    },[localeCtx.localeId])


    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        autoplay:true,
        autoplaySpeed: 5000,
        cssEase: "ease-in-out",
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    }

    return (
        featuredContentArray instanceof Array && featuredContentArray.length > 0 ?
            <div className='landing-featured-content-wrapper' style={styles.wrapper}>
                <Grid container direction='column' className='landing-featured-content-main-container'>
                    <Grid item className='landing-featured-content-title'>
                        
                        <StyledHTMLString typographyProps={{variant:matchMobile ? 'h5' : 'h4', style:styles.title}}>
                            {t('learnWithTheBest')}
                        </StyledHTMLString>
                    </Grid>
                    <Grid item className='landing-featured-content-item'>
                        <Carrousel
                            Slider={SliderBasic}
                            sliderProps={{ settings, className:'landing-featured-content-slider-inner-container' }}
                            hideSlider={featuredContentArray.length === 1 }
                        >
                            {featuredContentArray.map((featuredContent, index) => {
                                return(<FeaturedContent key={index} featuredContent={featuredContent} imgLazyLoadThreshold={imgLazyLoadThreshold}/>)
                            })}
                        </Carrousel>
                    </Grid>
                </Grid>
            </div>
            :
            null
    )
}

export default LandingFeaturedContent