import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles'
import './contentMultiSystem.css'
import DeviceNamesLogos from '../DeviceNamesLogos/DeviceNamesLogos';
import InfoLabelPopOver from '../InfoLabelPopOver/InfoLabelPopOver';
import { useMemo } from 'react';
import { isNotEmptyObject } from '../../aux/aux';
import StyledHTMLString from '../StyledHTMLString/StyledHTMLString';

const ContentMultiSystem = ({ description=undefined, contentCompatibleDeviceArray=[], contentDeviceVersionArray=[], title=undefined, subtitle=undefined, noVerticalPadding=false, stylesMap={}, widgetStylesMap={} }) => {
    const POPOVER_KEY_SUFFIX = '-popOverMsg';
    const { t } = useTranslation('contentProduct', { keyPrefix: 'contentMultiSystem'});
    const theme = useTheme();
    const matchMobile = useMediaQuery('(max-width: 680px)')
    const contentDeviceRelationshipTypeMap = useMemo(() => {
        let resultMap = {};
        let markedDevice = {};
        if(contentDeviceVersionArray && contentDeviceVersionArray.length > 0){
             contentDeviceVersionArray.forEach((deviceVersion, _) => {
                if(deviceVersion.deviceName && !markedDevice[deviceVersion.deviceName]){
                    markedDevice[deviceVersion.deviceName] = true;
                    if(resultMap.hasOwnProperty('designFor')){
                        resultMap.designFor.push(deviceVersion);
                    }else{
                        resultMap.designFor = [deviceVersion];
                    }
                }
            });
        }
        if(contentCompatibleDeviceArray && contentCompatibleDeviceArray.length > 0){
            contentCompatibleDeviceArray.forEach((compatibleDevice, _) => {
                if(compatibleDevice.deviceName && !markedDevice[compatibleDevice.deviceName]){
                    if(resultMap.hasOwnProperty('compatibleWith')){
                        resultMap.compatibleWith.push(compatibleDevice);
                    }else{
                        resultMap.compatibleWith = [compatibleDevice];
                    }
                }
            });
        }
        return resultMap
    },[contentCompatibleDeviceArray, contentDeviceVersionArray])
    const styles = {
        title:{
            fontWeight: 'bold',
            textAlign: 'center',
            ...stylesMap.title ? stylesMap.title : {}
        },
        subtitle:{
            fontWeight: 'bold',
            ...stylesMap.subtitle ? stylesMap.subtitle : {}
        },
        description:{
            whiteSpace: 'pre-line',
            ...stylesMap.description ? stylesMap.description : {}
        },
        marker: stylesMap.marker || {},
        versionContainer:{
            backgroundColor: theme.palette.surface.light[1],
            ...widgetStylesMap.container ? widgetStylesMap.container : {}
        },
        relationshipLabel:{
            color: theme.palette.grey[400],
            ...widgetStylesMap.label ? widgetStylesMap.label : {}
        },
        logoSvg:{
            height: '24px',
            fill: theme.palette.text.primary,
            ...widgetStylesMap.icon ? widgetStylesMap.icon : {}
        },
        footLabel:{
            color: theme.palette.grey[400],
            textAlign: 'center',
            ...widgetStylesMap.footLabel ? widgetStylesMap.footLabel : {}
        }
    }
    return (
        <div className='content-multisystem-wrapper'>
            <div className={`content-multisystem-main-container${noVerticalPadding ? ' no-vertical-padding' : ''}`}>
                {title &&
                <div className='content-multisystem-title-container'>
                        <Typography variant={matchMobile ? 'h5':'h4'} style={styles.title}>
                            {title}
                        </Typography>
                </div>
                }
                {subtitle &&
                    <div className='content-multisystem-subtitle-container'>
                        <Typography variant={matchMobile ? 'h6':'h5'} style={styles.subtitle}>
                            {subtitle}
                        </Typography>
                    </div>
                }
                <div className={`content-multisystem-content-container${noVerticalPadding ? ' no-vertical-padding' : ''}`}>
                    {description &&
                        <div className='content-multisystem-content-description-container'>
                            <StyledHTMLString typographyProps={{variant:'body1', style:styles.description}} liProps={{ marker: styles.marker }}>
                                {description}
                            </StyledHTMLString>
                        </div>
                    }
                    {isNotEmptyObject(contentDeviceRelationshipTypeMap) ? 
                        <div className='content-multisystem-content-multisystem-wrapper'>
                            <div className='content-multisystem-content-multisystem-container' style={styles.versionContainer}>
                                {Object.entries(contentDeviceRelationshipTypeMap).map(([relationshipType, deviceArray], index) => {
                                    return(
                                        deviceArray.length > 0 ?
                                            <div key={index} className='content-multisystem-content-devices-container'>
                                                <InfoLabelPopOver labelClassName={'content-multisystem-content-devices-label'} label={t(relationshipType)} labelPopOverMsg={t(`${relationshipType}${POPOVER_KEY_SUFFIX}`, '')}/>
                                                <DeviceNamesLogos 
                                                    className='content-multisystem-content-devices-logo-container' 
                                                    deviceArray={deviceArray}
                                                    svgStyle={styles.logoSvg}
                                                />
                                            </div>
                                            :
                                            null
                                        )
                                })}
                            </div>
                            <div className='content-multisystem-content-multisystem-footlabel'>
                                <Typography variant='body2' style={styles.footLabel}>
                                    {`* ${t('ifNotFindYourDeviceAskForCompatibility')}`}
                                </Typography>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    )
}

export default ContentMultiSystem