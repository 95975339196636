/**
 * This module contains content related user's services
 */
import { requestMinimumTimeoutWrapper } from "../aux/requestMethods";
import { 
    backUpUserLessonTypeProgressFn, backUpUserLessonActionProgressFn, backUpUserProgressFn, getUserContentProgressFn,
    getUserLessonActionProgressFn, getUserLessonProgressFn, getUserLessonTypeProgressFn, getUserLessonNotesFn,
    getUserSectionProgressFn, setUpUserLessonActionProgressFn, setUpUserLessonProgressFn, 
    setUpUserLessonTypeProgressFn, setUpUserSectionProgressFn, getUserContentConfigFn, backUpUserConfigFn, resyncUserProgressWithSystemFn, createUserLessonNoteFn, deleteUserLessonNoteFn, updateUserLessonNoteFn, getUserContentProductPreviewFn
} from "../aux/userContentQueries";
import { getContentProductStructureFn, getContentProductLessonFn, getContentProductLessonDownloadFn, getFileFromCDNFn } from "../aux/productQueries";

async function getContentProductStructure(userId, productId, localeId, system, query, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        localeId,
        system,
        query
    };
    return requestMinimumTimeoutWrapper( getContentProductStructureFn, requestParameters, minimumTimeOut, signal);
}

async function getContentProductLesson(userId, productId, lessonId, localeId, lessonVersionDecisor, minimumTimeOut=1000, signal=null){
    const requestParameters = {
        userId,
        productId,
        lessonId,
        localeId,
        lessonVersionDecisor
    };
    return requestMinimumTimeoutWrapper( getContentProductLessonFn, requestParameters, minimumTimeOut, signal);
}

async function getContentProductLessonDownload(userId, productId, lessonId, lessonVersionId, localeId, contentFileUri, contentFileType, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        lessonId,
        lessonVersionId,
        localeId,
        contentFileUri,
        contentFileType
    };
    return requestMinimumTimeoutWrapper( getContentProductLessonDownloadFn, requestParameters, minimumTimeOut, signal);
}
async function getUserLessonNotes(userId, productId, { lessonId, lessonVersionId }, filter={}, minimumTimeOut=0, signal=null ){
    const requestParameters = {
        userId,
        productId,
        lessonId,
        lessonVersionId,
        filter
    };
    return requestMinimumTimeoutWrapper( getUserLessonNotesFn, requestParameters, minimumTimeOut, signal)
}

/**
 * 
 * @param {*} userId 
 * @param {*} productId 
 * @param {*} systemConfig {system:{}, currentSystem:boolean}||undefined
 *  undefined retrieves all system configuration set by the user for this content
 *  system: {deviceName:string, softwareName:string}
 *  currentSystem: retrieves current user system configuration for the content
 * @param {*} minimumTimeOut 
 * @param {*} signal 
 * @returns 
 */
async function getUserContentProgress(userId, productId, systemConfig=undefined, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        ...( systemConfig ? 
                systemConfig.system ? 
                    { system: systemConfig.system } 
                    : 
                    systemConfig.currentSystem ? 
                        {justCurrentSystem: true} 
                        : 
                        {} 
                : 
                {}
            )
    };
    return requestMinimumTimeoutWrapper( getUserContentProgressFn, requestParameters, minimumTimeOut, signal);
}

async function getUserContentConfig(userId, productId, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId
    };
    return requestMinimumTimeoutWrapper( getUserContentConfigFn, requestParameters, minimumTimeOut, signal);
}

async function getUserSectionProgress(userId, productId, sectionId, system={}, query={}, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        sectionId,
        system,
        query
    };
    return requestMinimumTimeoutWrapper( getUserSectionProgressFn, requestParameters, minimumTimeOut, signal);
}

async function setUpUserSectionProgress(userId, productId, sectionId, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        sectionId,
    };
    return requestMinimumTimeoutWrapper( setUpUserSectionProgressFn, requestParameters, minimumTimeOut, signal );
}

async function getUserLessonProgress(userId, productId, sectionId, lessonId, lessonVersionDecisor, query={}, minimumTimeOut=0){
    const requestParameters = {
        userId,
        productId,
        lessonId,
        sectionId,
        lessonVersionDecisor,
        query
    };
    return requestMinimumTimeoutWrapper( getUserLessonProgressFn, requestParameters, minimumTimeOut);
}

async function setUpUserLessonProgress(userId, productId, sectionId, lessonId, lessonVersionId, minimumTimeOut=0){
    const requestParameters = {
        userId,
        productId,
        sectionId,
        lessonId,
        lessonVersionId
    };
    return requestMinimumTimeoutWrapper( setUpUserLessonProgressFn, requestParameters, minimumTimeOut );
}

async function getUserLessonTypeProgress(userId, productId, lessonId, lessonVersionId, lessonTypeName, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        lessonId,
        lessonVersionId,
        lessonTypeName,
    };
    return requestMinimumTimeoutWrapper( getUserLessonTypeProgressFn, requestParameters, minimumTimeOut, signal);
}

async function setUpUserLessonTypeProgress(userId, productId, lessonId, lessonVersionId, lessonTypeName, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        lessonId,
        lessonVersionId,
        lessonTypeName
    };
    return requestMinimumTimeoutWrapper( setUpUserLessonTypeProgressFn, requestParameters, minimumTimeOut, signal);
}

async function getUserLessonActionProgress(userId, productId, lessonId, lessonVersionId, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        lessonId,
        lessonVersionId
    };
    return requestMinimumTimeoutWrapper( getUserLessonActionProgressFn, requestParameters, minimumTimeOut, signal);
}


async function setUpUserLessonActionProgress(userId, productId, lessonId, lessonVersionId, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        lessonId,
        lessonVersionId,
    };
    return requestMinimumTimeoutWrapper( setUpUserLessonActionProgressFn, requestParameters, minimumTimeOut, signal);
}

async function getFileFromCDN(url, contentType, minimumTimeOut=0, signal=null){
    const requestParameters = {
        url,
        contentType,
    };
    return requestMinimumTimeoutWrapper( getFileFromCDNFn, requestParameters, minimumTimeOut, signal)
}
// 09/19 Unused
async function resyncUserProgressWithSystem(userId, productId, system, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        system
    };
    return requestMinimumTimeoutWrapper( resyncUserProgressWithSystemFn, requestParameters, minimumTimeOut, signal);
}

async function backUpUserConfig(userId, productId, updatedValues, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        updatedValues,
    };
    try{
        return requestMinimumTimeoutWrapper( backUpUserConfigFn, requestParameters, minimumTimeOut, signal);
    }catch(error){
        console.log('here')
        console.log(error)
        throw error
        // TODO: implement
    }
}

async function backUpUserProgress(userId, productId, contentIdsToUpdate, updatedValuesMap, minimumTimeOut=0){
    const requestParameters = {
        userId,
        productId,
        contentIdsToUpdate,
        updatedValuesMap
    };
    try{
        return requestMinimumTimeoutWrapper( backUpUserProgressFn, requestParameters, minimumTimeOut);
    }catch(error){
        console.log(error);
    }
}

async function backUpUserLessonActionProgress(userId, productId, actionIdToUpdate, updatedValues, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        actionIdToUpdate, 
        updatedValues
    };
    try{
        const res =  await requestMinimumTimeoutWrapper( backUpUserLessonActionProgressFn, requestParameters, minimumTimeOut, signal);
    }catch(error){
        console.log(error);
    }
}

async function backUpUserLessonTypeProgress(userId, productId, lessonTypeName, lessonIdsToUpdate, updatedValues, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        productId,
        lessonTypeName,
        lessonIdsToUpdate, 
        updatedValues
    };
    try{
        const res =  await requestMinimumTimeoutWrapper( backUpUserLessonTypeProgressFn, requestParameters, minimumTimeOut, signal);
    }catch(error){
        console.log(error);
    }
}

async function createUserLessonNote( userId, productId, { lessonId, lessonVersionId }, note, minimumTimeOut=0, signal=null ){
    const requestParameters = {
        userId,
        productId,
        lessonId,
        lessonVersionId,
        note
    };
    return requestMinimumTimeoutWrapper( createUserLessonNoteFn, requestParameters, minimumTimeOut, signal);
}

async function deleteUserLessonNote( userId, noteId, noteTypeName, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        noteId,
        noteTypeName
    };
    return requestMinimumTimeoutWrapper( deleteUserLessonNoteFn, requestParameters, minimumTimeOut, signal);
}

async function updateUserLessonNote( userId, noteId, noteTypeName, updatedValues, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        noteId,
        noteTypeName,
        updatedValues
    };
    return requestMinimumTimeoutWrapper( updateUserLessonNoteFn, requestParameters, minimumTimeOut, signal);
}

async function getUserContentProductPreview( userId, query={}, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        query
    }
    return requestMinimumTimeoutWrapper( getUserContentProductPreviewFn, requestParameters, minimumTimeOut, signal);
}

export{
    setUpUserSectionProgress,
    setUpUserLessonProgress,
    setUpUserLessonTypeProgress,
    setUpUserLessonActionProgress,
    getContentProductStructure,
    getContentProductLesson,
    getContentProductLessonDownload,
    getUserLessonNotes,
    getUserContentProductPreview,
    getUserContentConfig,
    getUserContentProgress,
    getUserSectionProgress,
    getUserLessonProgress,
    getUserLessonTypeProgress,
    getUserLessonActionProgress,
    getFileFromCDN,
    resyncUserProgressWithSystem,
    backUpUserConfig,
    backUpUserProgress,
    backUpUserLessonActionProgress,
    backUpUserLessonTypeProgress,
    createUserLessonNote,
    deleteUserLessonNote,
    updateUserLessonNote
}